//State da notificacao

export default function notificacao(state, action) {
    switch (action.idStore) {
        case 'NOTIFICATION':
            switch (action.type) {
                case 'SET':
                    return state = action.value
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}