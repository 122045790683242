
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//import { alertar } from "~/Util/alertar";
//import ReactWizard from "~/components/Wizard/Wizard";
//import ReactTable from "react-table";
import ReactTable from "react-table-6";
import * as controllerICMSs from "~/controller/controllerICMSs";
import * as controllerEmpresas from "~/controller/controllerEmpresa";
import * as controllerPesqUsuario from "~/controller/controllerPesqUsuario";
import classNames from "classnames";
//import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { Select } from "~/components/ErnComponets/ErnComponets";
import ArrowTooltip from "~/components/Tooltip/ArrowTooltip";
import { maskICMS, maskCPF } from "~/Util/mascaras";
import { useHotkeys } from "react-hotkeys-hook";
import {
  Card,
  Col,
  Button,
  ButtonGroup,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Row,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap";

//const idStore = "ICMS";
//const idStore = "EMPRESA";
//const idStoreEmpresa = "EMPRESA";
const idStore = "PESQUISA_USUARIOS"

const PesquisaUsuario = () => {
  const notificacao = useSelector((state) => state.notificacao);
  //const { empresa } = useSelector((state) => state.login.empresa);
  const login = useSelector((state) => state.login);
  const { pesquisaEmpresa, EMPRESA, pesqempresa, EMPRESAs } = useSelector((state) => state.EMPRESA);
  const {pesquisaUsuario, USUARIOs } = useSelector((state) => state.PESQUISA_USUARIOS);
  //const { ICMS, ICMSCopia, pesquisa, ICMSs, inicioCadastro, regimes } = useSelector((state) => state.ICMSs);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [dataTable, setDataTable] = useState([]);
  const [horizontalTabs, setHorizontalTabs] = useState("pesquisar");
  const [table, setTable] = useState(renderTable([]));
  const [tableVisible, setTableVisible] = useState(false);
  const [width, setWidht] = useState(window.innerWidth);

  useEffect(() => {
    setTable(renderTable());
  }, [dataTable, loading]);

  function renderTable() {
    return dataTable.map((obj, key) => {
      return {
        ...dataTable[key],
        actions: (
          <div className="actions-right">
            {!loading ? (
              <ArrowTooltip title="Editar">
                <></>
                {/* <Button
                  onClick={() => {
                    handleEditarCadastro(obj);
                  }}
                  size="sm"
                  className={classNames("btn-icon btn-link")}
                  color="success"
                >
                  <Icon icon="edit" className="fa-lg" />
                </Button> */}
              </ArrowTooltip>
            ) : (
              <></>
/*               <Button
                onClick={() => {
                  handleEditarCadastro(obj);
                }}
                size="sm"
                className={classNames("btn-icon btn-link")}
                color="success"
                disabled={true}
              >
                <Icon icon="edit" className="fa-lg" />
              </Button> */
            )}
            <ArrowTooltip title="Excluir">
            <></>
              {/* <Button
                onClick={() => {
                  handleExcluir(obj);
                }}
                className="btn-icon btn-link"
                color="danger"
              >
                <Icon icon="trash-alt" className="fa-lg" />
              </Button> */}
            </ArrowTooltip>
          </div>
        ),
      };
    });
  }

  //Monta Dados para Gride
  function tableData(data) {
    return data.map((prop, key) => {
      //let retorno = regimes.find((desc) => desc.codRegime === prop["codRegime"]);
      //let desc = "";
      //if (retorno) desc = retorno.descricao;
      return {
        id: key,
        idUsuario: prop["idUsuario"],
        nomeUsuario: prop["nomeUsuario"],
        cpf: maskCPF(prop["cpf"]),
        statusIntegracao: prop["statusIntegracao"],
      };
    });
  }


  //Se existir registro carrega e deixa a gride visivel
  useEffect(() => {
    setDataTable(tableData(USUARIOs));
    if (USUARIOs.length > 0) {
      setTableVisible(true);
    } else {
      setTableVisible(false);
    }
  }, [USUARIOs]);


  function handlePesquisar() {
    controllerPesqUsuario.Pesquisar(notificacao, dispatch, pesquisaUsuario, login.usuario.empresa); 
  }

  function handleLimpar() {
    dispatch({ idStore, type: "CLEAN_SEARCH" });
  }

/*   function changeActiveTab(tabName) {
    setHorizontalTabs(tabName);
    if (tabName === "pesquisar") {
      setTimeout(() => {
        document.getElementById("numeroICMS").focus();
      }, 100);
    }
  } */


  function handleOnChange(value, campo) {
    dispatch({ idStore, type: "PESQUISA", campo, value });
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      controllerPesqUsuario.Pesquisar(notificacao, dispatch, pesquisaUsuario);
    }
  }


  function onChangeEmpresa(value) {
    dispatch({ idStore, type: "PESQUISA", campo: "idEmpresa", value: value });
     //controllerPesqUsuario.Pesquisar(notificacao, dispatch, {...pesquisaEmpresa,}); 
    if (value === "0") {
      dispatch({ idStore, type: "EMPRESA_PESQ", value: [] });
    } 
/*     else {
      controllerEmpresas.GetEmpresas(
        notificacao,
        dispatch,
        login.usuario.empresa,
        { ...pesquisa, idEmpresa: value },
        (value) => {
          dispatch({ idStore, type: "EMPRESA_PESQ", value });
          dispatch({ idStore, type: "PESQUISA", campo: "idEmpresa", value: "0",});
        }
      );
    } */
  }

  function updateWidth() {
    setWidht(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    //Preenche combo de empresas
    //controllerEmpresas.GetEmpresas(notificacao, dispatch, login.usuario.empresa);
    dispatch({ idStore, type: "EMPRESA_PESQ", value: [] });
    dispatch({ idStore, type: "PESQUISA", campo: "idEmpresa", value: login.usuario.empresa });
  }, []);


  useHotkeys(
    "shift+l",
    () => {
      if (horizontalTabs === "pesquisar") {
        handleLimpar();
      } else if (horizontalTabs === "cadastrar") {
        dispatch({ idStore, type: "CLEAN_USUARIO" });
        dispatch({ idStore, type: "CLEAN_INVALIDO" });
      }
    },
    [horizontalTabs]
  );

  useHotkeys(
    "shift+p",
    () => {
      if (horizontalTabs === "pesquisar") {
        handlePesquisar();
      }
    },
    [pesquisaUsuario]
  );

  return (
    <>
      <div className="content">
        <Col className="mr-auto ml-auto" md="12">
          <Card>
{/*             <CardHeader className="text-center">
              <CardTitle tag="h3">
               Pesquisa de Usuários
              </CardTitle>
              <TabContent
                className="tab-space"
                activeTab={horizontalTabs}
                style={{ marginTop: "-30px", marginBottom: "-70px" }}
              >
                 <TabPane tabId="pesquisar">
                  <h4>Pesquisa de</h4>
                </TabPane> 

                 <TabPane tabId="cadastrar">
                  <h4>Cadastro de ICMS</h4>
                </TabPane> 
              </TabContent>
            </CardHeader> */}

            <CardBody>
              <ButtonGroup style={{ width: "100%" }}>
                <ArrowTooltip title="Pesquisa de Usuários">
                  <Button
                    color={horizontalTabs === "pesquisar" ? "info" : ""}
                    type="button"
                    data-toggle="tab"
                    className={classNames(
                      "btn-gp-padrao",
                      horizontalTabs === "pesquisar" ? "active" : ""
                    )}
                    /* onClick={() => {
                      changeActiveTab("pesquisar");
                    }} */
                    style={{ width: "50%" }}
                  >
                    Pesquisa de Usuários
                  </Button>
                </ArrowTooltip>
{/*                 <ArrowTooltip title="shift+2">
                  <Button
                    color={horizontalTabs === "cadastrar" ? "info" : ""}
                    type="button"
                    data-toggle="tab"
                    className={classNames(
                      "btn-gp-padrao",
                      horizontalTabs === "cadastrar" ? "active" : ""
                    )}
                    onClick={() => {
                      changeActiveTab("cadastrar");
                      novoCadatro();
                    }}
                    style={{ width: "50%" }}
                  >
                    CADASTRAR
                  </Button>
                </ArrowTooltip> */}
              </ButtonGroup>
            </CardBody>
            <CardBody>
            <Row className="padding-padrao-row">
{/*               <Col className="padding-padrao-col" lg="3" md="3">
                <label>Empresa</label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  id="pesqEmpresaSelect"
                  name="singleSelect"
                  //first={"Todos"}
                  
                  value={{ value: pesquisaUsuario.idEmpresa }}
                  select={{ value: "idEmpresa", label: "empresa" }}
                  options={EMPRESAs}
                  isSearchable={true}
                  onChange={(value) =>
                    onChangeEmpresa(value.value, "idEmpresa")
                  }                  
                  placeholder="Selecione"
                />
              </Col> */}
                  <Col className="padding-padrao-col" lg="2" md="3">
                    <label>CPF</label>
                    <Input
                      id="cpf"
                      type="text"
                      autoComplete="off"
                      value={pesquisaUsuario.cpf}
                      onChange={(e) =>
                        handleOnChange(e.target.value, e.target.id)
                      }
                      onKeyPress={(e) => handleKeyPress(e)}
                      autoFocus={true}
                      maxLength="11"
                    />
                  </Col>

                  <Col className="padding-padrao-col" lg="7" md="3">
                    <label>Nome</label>
                    <Input
                      id="nomeUsuario"
                      type="text"
                      autoComplete="off"
                      value={pesquisaUsuario.nomeUsuario}
                      onChange={(e) =>
                        handleOnChange(e.target.value, e.target.id)
                      }
                      onKeyPress={(e) => handleKeyPress(e)}
                      maxLength="50"
                    />
                  </Col>                  
                </Row>

                <Row>
                  <CardBody>
                    <Col style={{ width: "100%" }}>
                      <ArrowTooltip title="Use as teclas shift+L para limpar">
                        <Button
                          color="info"
                          style={
                            width > 550
                              ? { float: "right", width: "150px" }
                              : width < 550
                                ? { marginLeft: "0px", width: "100%" }
                                : {}
                          }
                          onClick={() => handleLimpar()}
                        >
                          Limpar
                        </Button>
                      </ArrowTooltip>
                      <ArrowTooltip title="Use as teclas shift+P para pesquisar">
                        <Button
                          color="info"
                          style={
                            width > 550
                              ? { float: "right", width: "150px" }
                              : width < 550
                                ? { marginLeft: "0px", width: "100%" }
                                : {}
                          }
                          onClick={() => handlePesquisar()}
                        >
                          Pesquisar
                        </Button>
                      </ArrowTooltip>
                    </Col>
                  </CardBody>
                </Row>

            </CardBody>
            <TabContent
              className="tab-space"
              activeTab={horizontalTabs}
              style={{ paddingBottom: "0px" }}
            >
              <TabPane tabId="pesquisar">
                {/* INCIO TabPesquisa-GRADE */}
                <div
                  style={
                    tableVisible
                      ? {
                          transition: "0.5s ease-in-out",
                          visibility: "visible",
                          opacity: "1",
                        }
                      : { visibility: "hidden", opacity: "0" }
                  }
                >
                  <ReactTable
                    data={table}
                    filterable={false}
                    columns={[
                      {
                        Header: "id Usuário",
                        accessor: "idUsuario",
                      },
                      {
                        Header: "Nome Completo",
                        accessor: "nomeUsuario",
                      },
                      {
                        Header: "CPF",
                        accessor: "cpf",
                      },
                      {
                        Header: "Status",
                        accessor: "statusIntegracao",
                      },

                     /*  {
                        Header: "",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      }, */
                    ]}
                    minRows={1}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    noDataText=""
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando"
                    pageText="Página"
                    ofText="de"
                    rowsText="linhas"
                  />
                </div>
                {/* FIM TabPesquisa-GRADE */}
              </TabPane>
              {/* FIM TabPesquisa */}
              {/* INICIO TabCadastro */}
{/*               <TabPane tabId="cadastrar">
                <div style={{ marginTop: "-20px" }}>
                  <ReactWizard
                    steps={steps}
                    navSteps={true}
                    title=""
                    description=""
                    validate
                    resizable={true}
                    previousButtonText="Voltar"
                    finishButtonText="Finalizar"
                    nextButtonText="Avançar"
                    goToFirstStep={inicioCadastro}
                    finishButtonClick={() => finishButtonClick()}
                    finishButtonClasses={classNames(
                      "btn-wd btn-info",
                      {
                        finishButtonMin: width < 550,
                      },
                      {
                        finishButtonRight: !width < 550,
                      }
                    )}
                    nextButtonClasses={classNames(
                      "btn-wd btn-info",
                      {
                        nextButtonMin: width < 550,
                      },
                      {
                        nextButtonRight: !width < 550,
                      }
                    )}
                    previousButtonClasses={classNames(
                      "btn-wd",
                      {
                        previousButtonMin: width < 550,
                      },
                      {
                        previousButtonRight: !width < 550,
                      }
                    )}
                    progressbar
                    color="blue"
                  />
                </div>
              </TabPane> */}
            </TabContent>
            {/* FIM TabCadastro */}
          </Card>
        </Col>
      </div>

      {/* Modal Excluir */}

{/*       <Modal isOpen={modalExcluir} toggle={toggleModalExcluir}>
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalExcluir}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">Excluir ICMS</h6>
        </div>

        <CardBody>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Button
                color="danger"
                type="button"
                onClick={() => {
                  toggleModalExcluir();
                }}
                style={{ width: "150px" }}
              >
                NÃO
              </Button>
            </Col>
            <Col className="text-center">
              <Button
                color="success"
                type="button"
                onClick={() => {
                  toggleModalExcluir();
                  excluir();
                }}
                style={{ width: "150px" }}
              >
                SIM
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Modal>
 */}
      {/* Modal Excluir*/}
    </>
  );
};

export default PesquisaUsuario;
