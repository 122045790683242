import React, { useState } from 'react'
import Loading from 'react-loading-bar'
import axios from 'axios';
import 'react-loading-bar/dist/index.css'

const LoadingBar = () => {
    const [show, setShow] = useState(false);

    axios.interceptors.request.use((config) => {
        setShow(true);
        return config
    });
    
    axios.interceptors.response.use((response) => {
        setTimeout(() => {
            setShow(false);
        }, 500);
        return response
    }, function (error) {
        setTimeout(() => {
            setShow(false);
        }, 500);
        return Promise.reject(error);
    });

    return (
        <Loading
            show={show}
            color="gold"
            showSpinner={true}
        />
    )
}

export default LoadingBar;