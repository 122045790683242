
import axios from 'axios';
import api from "../Util/api";
import { alertar } from "../Util/alertar";
import store from '../store/stores';

import { useSelector, useDispatch } from "react-redux";


const idStore = 'DASHBOARD';


export function Pesquisar(dispatch, user) {

    try {


      /*---------------------------------------------------------------------------
       * 
      ---------------------------------------------------------------------------*/
        //const login = useSelector((state) => state.login);
        var usuarioStorage = localStorage.getItem('USUARIO');
        let usuario = JSON.parse(usuarioStorage) //Converte JSON em Objeto
        var idEmpresa = '';

        if (usuario.empresa === "T") {
            idEmpresa = "1";
        }else{
            idEmpresa = usuario.empresa;
        }

/*         var labelGrafic = [];
        let dataGrafic = [];

        var labelGraficSemana = [];
        let dataGraficSemana = [];

        var labelGraficDiario = [];
        let dataGraficDiario = [];

        var labelGraficUserPerfil = [];
        let dataGraficUserPerfil = [];

        var dataInicioIntegracao = [];
        var dataFinalIntegracao = [];
        var statusIntegra = [];

        var dataErroIntegracao = [];
        var descricaoErroIntegracao = [];
        var statusErroIntegracao = [];
        var toolTip = [];


        var labelGraficCurso = [];
        let dataGraficCurso = [];

        var labelGraficDepartamento = [];
        let dataGraficDepartamento = [];
 */
        var ulrPesquisa = '';
        ulrPesquisa = api.urlIntegradorApi + api.urlGraficoDashboard + `${idEmpresa}` + `/${2024}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };        
        axios.request(config)
        .then((response) => {
            var graficos = response.data.data;
            carregarDadosDashboard(dispatch, graficos);

/*             var departamentos = 0;
            var perfisCadastrados = 0;
            var usuariosAtivos = 0;
            var errosDia = 0;
            var empresaNome = '';
            var idEmp = 0;

            if (graficos.graficoMes.length > 0){
                idEmp = graficos.graficoMes[0].idEmpresa;                 
            }

            departamentos = graficos.departamentos;
            perfisCadastrados = graficos.perfis;
            usuariosAtivos = graficos.usuariosAtivo;
            errosDia = graficos.erros;
            empresaNome = graficos.empresaNome;

            //let graficoObj = JSON.parse(graficos) //Converte JSON em Objeto
            //Grafico Mensal
            for (var i = 0; i < 12; i++) {
                labelGrafic.push(retornarMes(graficos.graficoMes[i].mes))
                dataGrafic.push(graficos.graficoMes[i].quantidade)
            }

            //Grafico Semanal
            var totalSemanas = graficos.graficoSemana.length;
            for (var i = 0; i < totalSemanas; i++) {
                labelGraficSemana.push(graficos.graficoSemana[i].primeiroDiaSemana)
                dataGraficSemana.push(graficos.graficoSemana[i].quantidade)
            }

            //Grafico Diario
            var totalDias = graficos.graficoDiario.length;
            for (var i = 0; i < totalDias; i++) {
                labelGraficDiario.push(graficos.graficoDiario[i].data)
                dataGraficDiario.push(graficos.graficoDiario[i].quantidade)
            }
            
            //Grafico Perfil de Usuários
            var totalUserPerfil = graficos.graficoMesUsuarioPerfil.length;
            var qtdUserPerfil = 0;
            for (var i = 0; i < totalUserPerfil; i++) {
                labelGraficUserPerfil.push(retornarMes(graficos.graficoMesUsuarioPerfil[i].mes))
                dataGraficUserPerfil.push(graficos.graficoMesUsuarioPerfil[i].quantidade)
                qtdUserPerfil += graficos.graficoMesUsuarioPerfil[i].quantidade;
            }

            //Grafico Mensal Cursos
            var qtdCursos = 0;
            for (var i = 0; i < 12; i++) {
                labelGraficCurso.push(retornarMes(graficos.graficoMesCursos[i].mes))
                dataGraficCurso.push(graficos.graficoMesCursos[i].quantidade)
                qtdCursos += graficos.graficoMesCursos[i].quantidade;
            }
            
            //Grafico Mensal Departamentos
            var qtdDepartamentos = 0;
            for (var i = 0; i < 12; i++) {
                labelGraficDepartamento.push(retornarMes(graficos.graficoMesDepartamentos[i].mes))
                dataGraficDepartamento.push(graficos.graficoMesDepartamentos[i].quantidade)
                qtdDepartamentos += graficos.graficoMesDepartamentos[i].quantidade;
            }

            //Table Ultimas Integrações
            var totalIntegracoes = graficos.ultimasIntegracoes.length;
            for (var i = 0; i < totalIntegracoes; i++) {
                dataInicioIntegracao.push(graficos.ultimasIntegracoes[i].dataHoraIntegracao)
                
                if (graficos.ultimasIntegracoes[i].statusIntegracao === "OK"){
                    let texto = graficos.ultimasIntegracoes[i].descricao;
                    let dataFinal = texto.substring(16, 35)
                    dataFinalIntegracao.push(dataFinal)
                }else{
                    let texto = graficos.ultimasIntegracoes[i].descricao;
                    let dataFinal = texto.substring(0, 25)
                    dataFinalIntegracao.push(dataFinal)
                }
                statusIntegra.push(graficos.ultimasIntegracoes[i].statusIntegracao)
            }

            
            //Table Erros do Dia
            var totalErros = graficos.ultimosErros.length;
            for (var i = 0; i < totalErros; i++) {
                dataErroIntegracao.push(graficos.ultimosErros[i].dataHoraIntegracao)
                
                let texto = graficos.ultimosErros[i].descricao;
                let descricaoErro = '';
                if (texto.length > 40){
                    descricaoErro = texto.substring(0, 40)
                }else{
                    descricaoErro = texto;
                }
                descricaoErroIntegracao.push(descricaoErro)
                statusErroIntegracao.push(graficos.ultimosErros[i].statusIntegracao)
                toolTip.push(texto);
            }


            const mensal = {
                labels: labelGrafic,
                dados: dataGrafic,
            }
    

            const semanal = {
                labels: labelGraficSemana,
                dados: dataGraficSemana,
            }

            const diario = {
                labels: labelGraficDiario,
                dados: dataGraficDiario,
            }

            const usuarioPerfil = {
                labels: labelGraficUserPerfil,
                dados: dataGraficUserPerfil,
            }

            const cursoMes = {
                labels: labelGraficCurso,
                dados: dataGraficCurso,
            }

            const departamentoMes = {
                labels: labelGraficDepartamento,
                dados: dataGraficDepartamento,
            }

            const ultimasIntegracoes  = {
                idEmpresa: idEmp,
                dataInicio: dataInicioIntegracao,
                dataFinal: dataFinalIntegracao,
                statusIntegracao: statusIntegra,
            }

            const ultimosErros = {
                dataErro: dataErroIntegracao,
                descricaoErro: descricaoErroIntegracao,
                statusErro: statusErroIntegracao,
                toolTipErro: toolTip,
            }


            dispatch({ idStore, type: 'CHART1', value: JSON.stringify(mensal) })

            dispatch({ idStore, type: 'CHART_SEMANAL', value: JSON.stringify(semanal) })
            
            dispatch({ idStore, type: 'CHART_DIARIO', value: JSON.stringify(diario) })

            dispatch({ idStore, type: 'CHART_USUARIO_PERFIL', value: JSON.stringify(usuarioPerfil) })

            dispatch({ idStore, type: 'QTD_USUARIO_PERFIL', value: qtdUserPerfil })

            dispatch({ idStore, type: 'CHART_CURSOS', value: JSON.stringify(cursoMes) })

            dispatch({ idStore, type: 'QTD_CURSOS', value: qtdCursos })

            dispatch({ idStore, type: 'CHART_DEPARTAMENTOS', value: JSON.stringify(departamentoMes) })

            dispatch({ idStore, type: 'QTD_DEPARTAMENTOS_INTEGRADOS', value: qtdDepartamentos })
            
            dispatch({ idStore, type: 'DEPARTAMENTOS', value: departamentos })

            dispatch({ idStore, type: 'PERFIS', value: perfisCadastrados })

            dispatch({ idStore, type: 'USUARIOS_ATIVOS', value: usuariosAtivos })

            dispatch({ idStore, type: 'ERROS', value: errosDia })

            dispatch({ idStore, type: 'EMPRESA_NOME', value: empresaNome })

            dispatch({ idStore, type: 'ULTIMAS_INTEGRACOES', value: JSON.stringify(ultimasIntegracoes) })

            dispatch({ idStore, type: 'ULTIMOS_ERROS', value: JSON.stringify(ultimosErros) })
 */            
           // return mensal
        })
/*         .then((response) => {
        //moment.locale('pt-br')
        let labelGrafic: string[] = []
        let dataGrafic: number[] = []
        response.data.data.map((dados: any) => {
            labelGrafic.push(dados.periodo)
            dataGrafic.push(dados.valor)
        }) */

        
        //})
    } catch (e) {
        console.log(e) 
        //alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }
}

function carregarDadosDashboard(dispatch, graficos){
    try {

        var labelGrafic = [];
        let dataGrafic = [];

        var labelGraficSemana = [];
        let dataGraficSemana = [];

        var labelGraficDiario = [];
        let dataGraficDiario = [];

        var labelGraficUserPerfil = [];
        let dataGraficUserPerfil = [];

        var dataInicioIntegracao = [];
        var dataFinalIntegracao = [];
        var statusIntegra = [];

        var dataErroIntegracao = [];
        var descricaoErroIntegracao = [];
        var statusErroIntegracao = [];
        var toolTip = [];


        var labelGraficCurso = [];
        let dataGraficCurso = [];

        var labelGraficDepartamento = [];
        let dataGraficDepartamento = [];


        var departamentos = 0;
        var perfisCadastrados = 0;
        var usuariosAtivos = 0;
        var errosDia = 0;
        var empresaNome = '';
        var idEmp = 0;

        if (graficos.graficoMes.length > 0){
            idEmp = graficos.graficoMes[0].idEmpresa;                 
        }

        departamentos = graficos.departamentos;
        perfisCadastrados = graficos.perfis;
        usuariosAtivos = graficos.usuariosAtivo;
        errosDia = graficos.erros;
        empresaNome = graficos.empresaNome;

        //let graficoObj = JSON.parse(graficos) //Converte JSON em Objeto
        //Grafico Mensal
        for (var i = 0; i < 12; i++) {
            labelGrafic.push(retornarMes(graficos.graficoMes[i].mes))
            dataGrafic.push(graficos.graficoMes[i].quantidade)
        }

        //Grafico Semanal
        var totalSemanas = graficos.graficoSemana.length;
        for (var i = 0; i < totalSemanas; i++) {
            labelGraficSemana.push(graficos.graficoSemana[i].primeiroDiaSemana)
            dataGraficSemana.push(graficos.graficoSemana[i].quantidade)
        }

        //Grafico Diario
        var totalDias = graficos.graficoDiario.length;
        for (var i = 0; i < totalDias; i++) {
            labelGraficDiario.push(graficos.graficoDiario[i].data)
            dataGraficDiario.push(graficos.graficoDiario[i].quantidade)
        }
        
        //Grafico Perfil de Usuários
        var totalUserPerfil = graficos.graficoMesUsuarioPerfil.length;
        var qtdUserPerfil = 0;
        for (var i = 0; i < totalUserPerfil; i++) {
            labelGraficUserPerfil.push(retornarMes(graficos.graficoMesUsuarioPerfil[i].mes))
            dataGraficUserPerfil.push(graficos.graficoMesUsuarioPerfil[i].quantidade)
            qtdUserPerfil += graficos.graficoMesUsuarioPerfil[i].quantidade;
        }

        //Grafico Mensal Cursos
        var qtdCursos = 0;
        for (var i = 0; i < 12; i++) {
            labelGraficCurso.push(retornarMes(graficos.graficoMesCursos[i].mes))
            dataGraficCurso.push(graficos.graficoMesCursos[i].quantidade)
            qtdCursos += graficos.graficoMesCursos[i].quantidade;
        }
        
        //Grafico Mensal Departamentos
        var qtdDepartamentos = 0;
        for (var i = 0; i < 12; i++) {
            labelGraficDepartamento.push(retornarMes(graficos.graficoMesDepartamentos[i].mes))
            dataGraficDepartamento.push(graficos.graficoMesDepartamentos[i].quantidade)
            qtdDepartamentos += graficos.graficoMesDepartamentos[i].quantidade;
        }

        //Table Ultimas Integrações
        var totalIntegracoes = graficos.ultimasIntegracoes.length;
        for (var i = 0; i < totalIntegracoes; i++) {
            dataInicioIntegracao.push(graficos.ultimasIntegracoes[i].dataHoraIntegracao)
            
            if (graficos.ultimasIntegracoes[i].statusIntegracao === "OK"){
                let texto = graficos.ultimasIntegracoes[i].descricao;
                let dataFinal = texto.substring(16, 35)
                dataFinalIntegracao.push(dataFinal)
            }else{
                let texto = graficos.ultimasIntegracoes[i].descricao;
                let dataFinal = texto.substring(0, 25)
                dataFinalIntegracao.push(dataFinal)
            }
            statusIntegra.push(graficos.ultimasIntegracoes[i].statusIntegracao)
        }

        
        //Table Erros do Dia
        var totalErros = graficos.ultimosErros.length;
        for (var i = 0; i < totalErros; i++) {
            dataErroIntegracao.push(graficos.ultimosErros[i].dataHoraIntegracao)
            
            let texto = graficos.ultimosErros[i].descricao;
            let descricaoErro = '';
            if (texto.length > 40){
                descricaoErro = texto.substring(0, 40)
            }else{
                descricaoErro = texto;
            }
            descricaoErroIntegracao.push(descricaoErro)
            statusErroIntegracao.push(graficos.ultimosErros[i].statusIntegracao)
            toolTip.push(texto);
        }


        const mensal = {
            labels: labelGrafic,
            dados: dataGrafic,
        }


        const semanal = {
            labels: labelGraficSemana,
            dados: dataGraficSemana,
        }

        const diario = {
            labels: labelGraficDiario,
            dados: dataGraficDiario,
        }

        const usuarioPerfil = {
            labels: labelGraficUserPerfil,
            dados: dataGraficUserPerfil,
        }

        const cursoMes = {
            labels: labelGraficCurso,
            dados: dataGraficCurso,
        }

        const departamentoMes = {
            labels: labelGraficDepartamento,
            dados: dataGraficDepartamento,
        }

        const ultimasIntegracoes  = {
            idEmpresa: idEmp,
            dataInicio: dataInicioIntegracao,
            dataFinal: dataFinalIntegracao,
            statusIntegracao: statusIntegra,
        }

        const ultimosErros = {
            dataErro: dataErroIntegracao,
            descricaoErro: descricaoErroIntegracao,
            statusErro: statusErroIntegracao,
            toolTipErro: toolTip,
        }


        dispatch({ idStore, type: 'CHART1', value: JSON.stringify(mensal) })

        dispatch({ idStore, type: 'CHART_SEMANAL', value: JSON.stringify(semanal) })
        
        dispatch({ idStore, type: 'CHART_DIARIO', value: JSON.stringify(diario) })

        dispatch({ idStore, type: 'CHART_USUARIO_PERFIL', value: JSON.stringify(usuarioPerfil) })

        dispatch({ idStore, type: 'QTD_USUARIO_PERFIL', value: qtdUserPerfil })

        dispatch({ idStore, type: 'CHART_CURSOS', value: JSON.stringify(cursoMes) })

        dispatch({ idStore, type: 'QTD_CURSOS', value: qtdCursos })

        dispatch({ idStore, type: 'CHART_DEPARTAMENTOS', value: JSON.stringify(departamentoMes) })

        dispatch({ idStore, type: 'QTD_DEPARTAMENTOS_INTEGRADOS', value: qtdDepartamentos })
        
        dispatch({ idStore, type: 'DEPARTAMENTOS', value: departamentos })

        dispatch({ idStore, type: 'PERFIS', value: perfisCadastrados })

        dispatch({ idStore, type: 'USUARIOS_ATIVOS', value: usuariosAtivos })

        dispatch({ idStore, type: 'ERROS', value: errosDia })

        dispatch({ idStore, type: 'EMPRESA_NOME', value: empresaNome })

        dispatch({ idStore, type: 'ULTIMAS_INTEGRACOES', value: JSON.stringify(ultimasIntegracoes) })

        dispatch({ idStore, type: 'ULTIMOS_ERROS', value: JSON.stringify(ultimosErros) })

        
    } catch (error) {
        console.log(error)
    }
}

function retornarMes(numeroMes){
    switch (numeroMes) {
        case 1:
            return "JAN"
        case 2:
            return "FEV"
        case 3:
            return "MAR"
        case 4:
            return "ABR"
        case 5:
            return "MAI"
        case 6:
            return "JUN"
        case 7:
            return "JUL"
        case 8:
            return "AGO"
        case 9:
            return "SET"
        case 10:
            return "OUT"
        case 11:
            return "NOV"
        case 12:
            return "DEZ"
        default:
            break;
    }
}

/* export async function getGraficMonthly() {
  try {

      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
      return await axios.get(api.urlMovimento + `api/v1/venda/?tipo=M`,{
          headers: { Authorization: bearerAuth }
      }).then(
          function (response: any) {
              moment.locale('pt-br')
              let labelGrafic: string[] = []
              let dataGrafic: number[] = []
              response.data.data.map((dados: any) => {
                  labelGrafic.push(moment(dados.periodo).format('MM/YYYY'))
                  dataGrafic.push(dados.valor)
              })

              const mensal = {

                  labels: labelGrafic,
                  datasets: [{
                      label: "Valores de Pedido de Vendas",
                      borderColor: "#1f8ef1",
                      borderWidth: 2,
                      borderDash: [],
                      borderDashOffset: 0.0,
                      pointBackgroundColor: "#1f8ef1",
                      pointBorderColor: "rgba(255,255,255,0)",
                      pointHoverBackgroundColor: "#1f8ef1",
                      pointBorderWidth: 20,
                      pointHoverRadius: 4,
                      pointHoverBorderWidth: 15,
                      pointRadius: 4,
                      data: dataGrafic
                  }]
              }

              return mensal

          })

  } catch (error) {

  }
}
 */
