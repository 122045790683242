import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerICMSs from "~/controller/controllerICMSs";
import * as controllerUsuarioLogin from "~/controller/controllerUsuarioLogin"
import { Select } from "~/components/ErnComponets/ErnComponets";
import { maskICMS } from "~/Util/mascaras";
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip'; 
//import Switch from "react-bootstrap-switch";
import { Switch } from "~/components/ErnComponets/ErnComponets";

import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,
  CardTitle,
  CustomInput,
} from "reactstrap";

// core components
import ImageUpload from "~/components/CustomUpload/ImageUpload"

const idStore = "USUARIO_LOGIN";



const UsuarioCadastro = () => {
  const notificacao = useSelector(state => state.notificacao);
  const login = useSelector((state) => state.login);
  const { UsuarioLogin, UsuarioLoginCopia, invalido } = useSelector((state) => state.UsuarioLogin);

  //const { codEmpresa } = useSelector(state => state.login.usuario.empresa);
  //const { ICMS, ICMSCopia,  regimes } = useSelector(state => state.ICMSs);
  const dispatch = useDispatch();


  const [isEnabled, setIsEnabled] = useState(false);
  //const toggleSwitch = () => setIsEnabled(previousState => !previousState);

  //Carregar das uma vez
   useEffect(() => {
    handleStatus(true);
   }, [])
 

  function handleOnChange(value, campo) {
    dispatch({ idStore, type: "USUARIO_LOGIN", campo: "idEmpresa", value: login.usuario.empresa }); 
    dispatch({ idStore, type: "USUARIO_LOGIN", campo, value });
    dispatch({ idStore, type: "INVALIDO", campo, value });
  }

  function handleStatus(campo){
    var statusUser = '';
    if (isEnabled){
      statusUser = 'I';
      setIsEnabled(false);
    }else{
      statusUser = 'A';
      setIsEnabled(true);
    }
    
    dispatch({ idStore, type: 'USUARIO_LOGIN', campo: campo, value: statusUser })
  }

  function SetImgPerfil(value) {
    dispatch({ idStore, type: 'USUARIO_LOGIN', campo: "fotoUsuario", value: value });
  };

  async function Salvar(e) {
    e.preventDefault();
    if (UsuarioLogin.idUsuario === '') {
      controllerUsuarioLogin.CreateUsuarioLogin(notificacao, dispatch, UsuarioLogin, 'I', UsuarioLoginCopia);
    } else {
      controllerUsuarioLogin.CreateUsuarioLogin(notificacao, dispatch, UsuarioLogin, 'A', UsuarioLoginCopia);
    }
  };

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_USUARIO_LOGIN' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    document.getElementById('loginUsuario').focus()
  };

  return (
    <>
      <Row className="justify-content-center" style={{ marginTop: "-70px" }}>
        <Col>
          {/* <h4 className="info-text">Preencha com as informações do Usuário</h4> */}
          <CardBody>

            <Row className="justify-content-center">
              <Col md="10">
                <Row>

                  <Col className="padding-padrao-col" lg="2" md="2">
                    <label>Id do Usuário</label>
                      <Input
                        id="idUsuario"
                        type="text"
                        disabled
                        value={UsuarioLogin.idUsuario}
                        //onChange={e => onChange(e.target.value, 'idUsuario')}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        autoComplete="off"
                      />
                      <br></br>
                  </Col>

                  <Col className="padding-padrao-col" lg="3" md="3">
                    <label>Login</label>
                    <FormGroup className={invalido.login ? "has-danger" : ''}>
                      <Input
                        id="loginUsuario"
                        type="text"
                        value={UsuarioLogin.loginUsuario}
                        //onChange={e => onChange(e.target.value, 'login')}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        autoComplete="off"
                        maxLength="30"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg="7" md="3">
                    <label>Nome</label>
                    <FormGroup className={invalido.nome ? "has-danger" : ''}>
                      <Input
                        id="nomeUsuario"
                        type="text"
                        value={UsuarioLogin.nomeUsuario}
                        //onChange={e => onChange(e.target.value, 'nome')}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }    
                        autoComplete="off"
                        maxLength="80"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>


                </Row>

                <Row style={{ marginTop: '-25px' }}>
                <Col className="padding-padrao-col" lg="4" md="2" >
                    <label>Email</label>
                    <FormGroup className={invalido.email ? "has-danger" : ''}>
                      <Input
                        id="emailUsuario"
                        type="text"
                        value={UsuarioLogin.emailUsuario}
                        //onChange={e => onChange(e.target.value, 'email')}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }
                        autoComplete="off"
                        maxLength="80"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg="3" md="3">
                    <label>Senha</label>
                    <FormGroup className={invalido.senha ? "has-danger" : ''}>
                      <Input
                        id="senhaUsuario"
                        type="password"
                        value={UsuarioLogin.senhaUsuario}
                        //onChange={e => onChange(e.target.value, 'senhaUsuario')}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }    
                        autoComplete="off"
                        maxLength="30"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>

                  <Col className="padding-padrao-col" lg="3" md="3">
                    <label>Confirmar Senha</label>
                    <FormGroup className={invalido.senhaConfirmar ? "has-danger" : ''}>
                      <Input
                        id="senhaConfirmar"
                        type="password"
                        value={UsuarioLogin.senhaConfirmar}
                        //onChange={e => onChange(e.target.value, 'senhaConfirmar')}
                        onChange={(e) =>
                          handleOnChange(e.target.value, e.target.id)
                        }    
                        autoComplete="off"
                        maxLength="30"
                      />
                      <br></br>
                    </FormGroup>
                  </Col>


                   <Col className="text-center" md="2" >
                    <label>Status</label>
                    <p></p>
                      <Col md="10" className="text-right">
                        <Switch
                          componentleft={<label className={UsuarioLogin.statusUsuario === "A" ? `text-success text-center` : "text-warning text-center"}>{UsuarioLogin.statusUsuario === "A" ? "Ativo" : "Inativo"}</label>}
                          checked={UsuarioLogin.statusUsuario === "A"}
                          onChange={(e) =>
                            handleStatus("statusUsuario")
                          }
                          value={isEnabled}
                        />
                      </Col>                  



{/*                     <p className="adjustments-line">
                      <div >
                        <span className="label-switch">Inativo</span>
                        <Switch
                          //id="statusUsuario"
                          //onChange={this.handleActiveMode}
                          onChange={(e) =>
                             handleStatus("statusUsuario")
                          }
                          //value={this.state.darkMode}
                          checked={UsuarioLogin.statusUsuario === 'A'}
                          onText=""
                          offText=""
                          trackColor={{false: '#767577', true: '#81b0ff'}}
                          thumbColor={isEnabled ? '#f5dd4b' : '#06661e'}
                          value={isEnabled}

                        />
                        <span className="label-switch">Ativo</span>
                      </div>
                    </p> */}
                  </Col>
 

{/*                       <Col className="padding-padrao-col" lg="2" md="3" >
                        <p className="category">Status</p>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">Inativo</span>
                          <CustomInput
                            type="switch"
                            id="switch-5"
                            className="mt-n4"
                          />
                          <span className="ml-n2">Ativo</span>
                        </div>
                      </Col> */}

                </Row>

                <Row>
                <Col md='5' style={{ marginTop: '-20px' }}>
                    <h5>Foto de Perfil</h5>
                    <ImageUpload
                        id="fotoUsuario"
                        changeBtnClasses="btn-simple btn-padrao"
                        addBtnClasses="btn-simple"
                        removeBtnClasses="btn-simple btn-padrao"
                        changeBtnColor="info"
                        addBtnColor="info"
                        avatar
                        imagem={UsuarioLogin.fotoUsuario}
                        //imagePreviewBase64={UsuarioLogin.fotoUsuario}
                        // onChange={(e) =>
                        //   handleOnChange(e.target.value, e.target.id)
                        // }
                        onChange={(e) =>
                          SetImgPerfil(e)}
                        //onChange={SetImgPerfil}
                        //onChange={(e: any) => actions.setUsuario({ ...usuario, foto: e })}
                        //disabled={verCadastro}
                    />
                </Col>


{/*                   <Col md="3" sm="4">
                    <CardTitle tag="h4">Foto</CardTitle>
                    <ImageUpload
                      avatar
                      addBtnColor="default"
                      changeBtnColor="default"
                    />
                  </Col> */}
                </Row>

              </Col>

            </Row>

          </CardBody>
          <CardFooter className="text-center">
            <Row className="justify-content-center">
              <Col md="10" >
                <Col className="text-center">
                <ArrowTooltip title='shift+L'><Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Limpar</Button></ArrowTooltip>
                <ArrowTooltip title='shift+S'><Button className="btn-padrao" color="info" onClick={(e) => Salvar(e)}>Salvar</Button></ArrowTooltip>
                </Col>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>





    </>
  )
};

//export default UsuarioCadastro;

 class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      document.getElementById('nomeUsuario').focus()
    }, 100)
  }

  render() {
    return (
      <UsuarioCadastro />
    )
  }
}

export default Wizard;
