//State da tela de Empresas

var INITIAL_STATE = {

//Campos utiliazado na tela de pesquisa da empresa  
  pesquisaEmpresa: {
      idEmpresa: 0,
      nomeFantasia: '',
  },
 

  //Campos para usar no cadastro/alteração
  EMPRESA: {
    idEmpresa: 0,
    empresa: '',
    secret: '',
    identFier: '',
    nomeFantasia: '',
    urlAPi: '',
    ativo: 0,
    medida: '',
    tempo: 1,
  },

  //Campos para validar na pesquisa
  invalido: {
    idEmpresa: false,
    nomeFantasia: false,
},

  //Objetos para carregar lista de dados
  pesqempresa: [],
  EMPRESAs: [],
  //ICMSs: [],
  //inicioCadastro: false,
  //pesqregime: [],
  //cadregime: [],
  EMPRESACopia: {},

}

export default function EMPRESA(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'EMPRESA':
          switch (action.type) {
              case 'PESQUISA':
                  return { ...state, pesquisaEmpresa: { ...state.pesquisaEmpresa, [action.campo]: action.value } }
              case 'EMPRESAS':
                  return { ...state, EMPRESAs: action.value }
              case 'CLEAN_SEARCH':
                  return {
                      ...state,
                      pesquisaEmpresa: {
                        idEmpresa: 0,
                        nomeFantasia: '',
                      },
                      EMPRESAs: []
                  }
              case 'INVALIDO':
                  return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
              case 'EMPRESA':
                  return { ...state, EMPRESA: { ...state.EMPRESA, [action.campo]: action.value } }
              case 'INICIO_CADASTRO':
                  return { ...state, inicioCadastro: action.value }
              case 'EMPRESA_COPIA':
                  return { ...state, ENORESACopia: state.EMPRESA }
              case 'EMPRESA_COPIA_STATUS':
                  return { ...state, EMPRESACopia: { ...state.EMPRESA, status: action.value } }
              case 'CLEAN_EMPRESA':
                  return {
                      ...state,
                      EMPRESA: {
                        idEmpresa: 0,
                        empresa: '',
                        secret: '',
                        identFier: '',
                        nomeFantasia: '',
                        urlAPi: '',
                        ativo: 0,
                        medida: '',
                        tempo: 1,                    
                      }
                  }
              case 'EMPRESA_PESQ':
                  return { ...state, pesqempresa: action.value }
              //case 'REGIMES_CAD':
              //    return { ...state, cadregime: action.value }
              case 'CLEAN_INVALIDO':
                  return {
                      ...state,
                      invalido: {
                        idEmpresa: false,
                        nomeFantasia: false,                    
                      }
                  }

              default:
                  return { ...state }
          }
      default:
          return { ...state }
  }
}




