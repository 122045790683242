var api = {
  //urlIntegradorApi: "http://localhost:5590/",
  urlIntegradorApi: "https://producao.ernetwork.com.br:5590/",
  //Urls do App
  urlValidarToken: "token/validartoken/",
  urlEmpresas: "empresa/empresatodas/",
  urlPesqUsuarios: "usuariopesq/usuariosempresa/",
  urlPesqUsuarioCpf: "usuariopesq/usuariocpf/",
  urlPesqUsuarioNome: "usuariopesq/usuariosnome/",
  urlCadastroUsuarioPesquisa: "usuario/usuarionomeloginemail/",
  urlCadastroUsuario: "usuario/incluiralterar",
  urlCadastroUsuarioGetIdUsuario: "usuario/usuarioget/",
  urlCadastroUsuarioAtivarInativar: "usuario/usuarioativarinativar/",
  //Graficos
  urlGraficoDashboard: "grafico/graficodashboardget/",
  urlErnAdmin: "http://prod.ernetwork.com.br:5555/",
  urlCadastro: "http://desenv.ernetwork.com.br:5004/",
  apiUser: "xX0F_etyDoMilkhen26ayc91bnz9vz3LQyyABU8=",
  apiPass: "JIYw4iTX74GsllxV9aSQloK7JPUGiQCK1IDzJUXvfjLXdY8=",
};
export default api;
