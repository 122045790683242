//State da tela de Login
var INITIAL_STATE = {};
if (window.localStorage.getItem('USUARIO')) {
    INITIAL_STATE = {
        usuario: JSON.parse(window.localStorage.getItem('USUARIO')),
        objetos: {
            loginInvalido: false,
            senhaInvalida: false,
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
        }
    }
} else {
    INITIAL_STATE = {
        usuario: {
            idUsuarioLogin: '',
            login: '',
            email: '',
            nome: '',
            empresa: '',
            token: '',
            foto: '',
            usuarioExiste: false,
            loginSucesso: false,
        },
        objetos: {
            loginInvalido: false,
            senhaInvalida: false,
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
        }
    }
};

export default function login(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'LOGIN':
            switch (action.type) {
                case 'USUARIO':
                    return { ...state, usuario: { ...state.usuario, [action.campo]: action.value } };
                case 'OBJETOS':
                    return { ...state, objetos: { ...state.objetos, [action.campo]: action.value } };
                //case 'NOVA_SENHA':
                //    return { ...state, novaSenha: { ...state.novaSenha, [action.campo]: action.value } };
                case 'SAVE':
                    window.localStorage.setItem('USUARIO', JSON.stringify(state.usuario));
                    return { ...state }
                case 'LOGOUT':
                    return {
                        ...state,
                        usuario: {
                            idUsuarioLogin: '',
                            login: '',
                            email: '',
                            nome: '',
                            empresa: '',
                            token: '',
                            foto: '',
                            usuarioExiste: false,
                            loginSucesso: false,                
                        },
                        objetos: {
                            loginInvalido: false,
                            senhaInvalida: false,
                            percentage1: 0,
                            percentage2: 0,
                            percentage3: 0,
                        }
                    };
                    case 'LOCK': {
                        return {...state,
                            usuario: {
                                usuarioExiste: true,
                            },
                         };
                    }
                default:
                    return { ...state };
            }
        default:
            return { ...state }
    }
}

/* //State da tela de Login
var INITIAL_STATE = {};
if (window.localStorage.getItem('USUARIO')) {
    INITIAL_STATE = {
        usuario: JSON.parse(window.localStorage.getItem('USUARIO')),
        objetos: {
            loginInvalido: false,
            senhaInvalida: false,
            novaSenhaInvalida: false,
            confirmarSenhaInvalida: false,
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            novaSenha: '',
            confirmarSenha: '',
            primeiroAcesso: false,
            alterarSenha: false,
        },
        novaSenha: {
            login: '',
            loginInvalido: '',
            usuarioExiste: '',
        }
    }
} else {
    INITIAL_STATE = {
        usuario: {
            token: '',
            codUser: '',
            login: '',
            senha: '',
            apelido: 'Usuário',
            foto: '',
            empresa: [],
            empresas: [],
            usuarioExiste: false,
            loginSucesso: false,
        },
        objetos: {
            loginInvalido: false,
            senhaInvalida: false,
            novaSenhaInvalida: false,
            confirmarSenhaInvalida: false,
            percentage1: 0,
            percentage2: 0,
            percentage3: 0,
            novaSenha: '',
            confirmarSenha: '',
            primeiroAcesso: false,
            alterarSenha: false,
        },
        novaSenha: {
            login: '',
            loginInvalido: '',
        }
    }
};

export default function login(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'LOGIN':
            switch (action.type) {
                case 'USUARIO':
                    return { ...state, usuario: { ...state.usuario, [action.campo]: action.value } };
                case 'OBJETOS':
                    return { ...state, objetos: { ...state.objetos, [action.campo]: action.value } };
                case 'NOVA_SENHA':
                    return { ...state, novaSenha: { ...state.novaSenha, [action.campo]: action.value } };
                case 'SAVE':
                    window.localStorage.setItem('USUARIO', JSON.stringify(state.usuario));
                    return { ...state }
                case 'LOGOUT':
                    return {
                        ...state,
                        usuario: {
                            token: '',
                            codUser: '',
                            login: '',
                            senha: '',
                            apelido: 'Usuário',
                            foto: '',
                            empresa: [],
                            empresas: [],
                            usuarioExiste: false,
                            loginSucesso: false,
                        },
                        objetos: {
                            loginInvalido: false,
                            senhaInvalida: false,
                            novaSenhaInvalida: false,
                            confirmarSenhaInvalida: false,
                            percentage1: 0,
                            percentage2: 0,
                            percentage3: 0,
                            novaSenha: '',
                            confirmarSenha: '',
                            primeiroAcesso: false,
                            alterarSenha: false,
                        },
                        novaSenha: {
                            login: '',
                            loginInvalido: '',
                        }
                    };
                    case 'LOCK': {
                        return {...state,
                            usuario: {
                                usuarioExiste: true,
                            },


                         };
                    }
                default:
                    return { ...state };
            }
        default:
            return { ...state }
    }
} */