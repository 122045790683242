//State da tela de Pesquisa e cadastro de grupos

var INITIAL_STATE = {

  DASHBOARD: {
    departamentos: 0,
    perfis: 0,
    usuariosAtivo: 0,
    erros: 0,
  },

  pesqempresa: [],
  EMPRESAs: [],
  USUARIOs: [],
  
}

export default function DASHBOARD(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'DASHBOARD':
          switch (action.type) {
               case 'CHART1':
                    window.localStorage.setItem('CHART1', action.value);
                    return { ...state }                  
               case 'CHART_SEMANAL':
                    window.localStorage.setItem('CHART_SEMANAL', action.value);
                    return { ...state }                  
               case 'CHART_DIARIO':
                    window.localStorage.setItem('CHART_DIARIO', action.value);
                    return { ...state }          
                case 'CHART_USUARIO_PERFIL':
                    window.localStorage.setItem('CHART_USUARIO_PERFIL', action.value);
                    return { ...state }          
                case 'QTD_USUARIO_PERFIL':
                    window.localStorage.setItem('QTD_USUARIO_PERFIL', action.value);
                    return { ...state }          
                case 'CHART_CURSOS':
                    window.localStorage.setItem('CHART_CURSOS', action.value);
                    return { ...state }          
                case 'QTD_CURSOS':
                    window.localStorage.setItem('QTD_CURSOS', action.value);
                    return { ...state }  
                case 'CHART_DEPARTAMENTOS':
                    window.localStorage.setItem('CHART_DEPARTAMENTOS', action.value);
                    return { ...state }          
                case 'QTD_DEPARTAMENTOS_INTEGRADOS':
                    window.localStorage.setItem('QTD_DEPARTAMENTOS_INTEGRADOS', action.value);
                    return { ...state }          
               case 'DEPARTAMENTOS':
                    window.localStorage.setItem('DEPARTAMENTOS', action.value);
                    return { ...state }          
               case 'PERFIS':
                    window.localStorage.setItem('PERFIS', action.value);
                    return { ...state }          
                case 'USUARIOS_ATIVOS':
                    window.localStorage.setItem('USUARIOS_ATIVOS', action.value);
                    return { ...state }          
                case 'ERROS':
                    window.localStorage.setItem('ERROS', action.value);
                    return { ...state }          
                case 'EMPRESA_NOME':
                    window.localStorage.setItem('EMPRESA_NOME', action.value);
                    return { ...state }          
                case 'ULTIMAS_INTEGRACOES':
                    window.localStorage.setItem('ULTIMAS_INTEGRACOES', action.value);
                    return { ...state }          
                case 'ULTIMOS_ERROS':
                    window.localStorage.setItem('ULTIMOS_ERROS', action.value);
                    return { ...state }          
              default:
                  return { ...state }
          }
      default:
          return { ...state }
  }
}




