//State da tela de Pesquisa e cadastro de grupos

var INITIAL_STATE = {

  pesquisaLogin: {
      idEmpresa: 0,
      idUsuario: 0,
      nomeUsuario: '',
      loginUsuario: '',
      emailUsuario: '',
      statusUsuario: '',
  },

  UsuarioLogin: {
      idUsuario: '',
      loginUsuario: '',
      senhaUsuario: '',
      senhaConfirmar: '',
      emailUsuario: '',
      nomeUsuario: '',
      fotoUsuario: '',
      idEmpresa: '',
      //dataCadastro: '',
      statusUsuario: '',
  },

  invalido: {
    login: false,
    senha: false,
    senhaConfirmar: false,
    email: false,
    nome: false,
  },

  USUARIOS_LOGINs: [],
  inicioCadastro: false,
  UsuarioLoginCopia: {},

}

export default function USUARIO_LOGIN(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'USUARIO_LOGIN':
          switch (action.type) {
              case 'PESQUISA':
                  return { ...state, pesquisaLogin: { ...state.pesquisaLogin, [action.campo]: action.value } }
              case 'USUARIOS_LOGINs':
                  return { ...state, USUARIOS_LOGINs: action.value }
              case 'CLEAN_SEARCH':
                  return {
                      ...state,
                      pesquisaLogin: {
                        idEmpresa: 0,
                        idUsuario: '',
                        nomeUsuario: '',
                        loginUsuario: '',
                        emailUsuario: '',
                        statusUsuario: '',                  
                      },
                      USUARIOS_LOGINs: []
                  }
              case 'INVALIDO':
                  return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
              case 'USUARIO_LOGIN':
                  return { ...state, UsuarioLogin: { ...state.UsuarioLogin, [action.campo]: action.value } }
              case 'INICIO_CADASTRO':
                  return { ...state, inicioCadastro: action.value }
              case 'USUARIO_LOGIN_COPIA':
                  return { ...state, UsuarioLoginCopia: state.UsuarioLogin }
              case 'USUARIO_LOGIN_COPIA_STATUS':
                  return { ...state, UsuarioLoginCopia: { ...state.UsuarioLogin, status: action.value } }
              case 'CLEAN_USUARIO_LOGIN':
                  return {
                      ...state,
                      UsuarioLogin: {
                        idUsuario: '',
                        loginUsuario: '',
                        senhaUsuario: '',
                        senhaConfirmar: '',
                        emailUsuario: '',
                        nomeUsuario: '',
                        fotoUsuario: '',
                        idEmpresa: '',
                        statusUsuario: '',
                      }
                  }
              case 'CLEAN_INVALIDO':
                  return {
                      ...state,
                      invalido: {
                        login: false,
                        senha: false,
                        senhaConfirmar: false,
                        email: false,
                        nome: false,                    
                      }
                  }

              default:
                  return { ...state }
          }
      default:
          return { ...state }
  }
}




