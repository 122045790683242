import React from "react";
import ReactDOM from "react-dom";
import App from "~/App";
import { Provider } from "react-redux";
import store from "~/store/stores";
import "~/assets/css/nucleo-icons.css";
import "~/assets/scss/black-dashboard-pro-react.scss?v=1.0.0";
import "~/assets/demo/demo.css";
import "react-notification-alert/dist/animate.css";
import "~/estilo.css";
import { library } from "@fortawesome/fontawesome-svg-core";
/* import {
  faBan,
  faTrashAlt,
  faEdit,
  faRedo,
  faCaretLeft,
  faCaretRight,
  faExternalLinkAlt,
  faSearch,
  faQuestionCircle,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faBan,
  faTrashAlt,
  faEdit,
  faRedo,
  faCaretLeft,
  faCaretRight,
  faExternalLinkAlt,
  faSearch,
  faQuestionCircle,
  faCheck
);
 */
import {
  faBan,
  faCircle,
  faCalendar,
  faTrashAlt,
  faEdit,
  faEye,
  faRedo,
  faCaretLeft,
  faCaretRight,
  faCashRegister,
  faExternalLinkAlt,
  faSearch,
  faQuestionCircle,
  faCheck,
  faTruck,
  faLockOpen,
  faShare,
  faFileAlt,
  faDollarSign,
  faSave,
  faPlusCircle,
  faReceipt,
  faCaretDown,
  faCaretUp,
  faPlus,
  faStar,
  faTh,
  faQuestion,
  faMinusSquare,
  faExclamationTriangle,
  faTimes,
  faDownload,
  faFileDownload,
  faUserCheck,
  faWallet,
  faMoneyBill,
  faScroll,
  faInfoCircle,
  faStickyNote,
  faEnvelope,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faBan,
  faCircle,
  faCalendar,
  faTrashAlt,
  faEdit,
  faEye,
  faRedo,
  faCaretLeft,
  faCaretRight,
  faExternalLinkAlt,
  faSearch,
  faQuestionCircle,
  faCheck,
  faTruck,
  faFileAlt,
  faShare,
  faLockOpen,
  faCashRegister,
  faStickyNote,
  faReceipt,
  faDollarSign,
  faSave,
  faPlusCircle,
  faCaretDown,
  faCaretUp,
  faPlus,
  faStar,
  faTh,
  faQuestion,
  faMinusSquare,
  faExclamationTriangle,
  faTimes,
  faDownload,
  faFileDownload,
  faUserCheck,
  faWallet,
  faMoneyBill,
  faScroll,
  faInfoCircle,
  faEnvelope,
  faPrint
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
