import Dashboard from "~/views/Dashboard.jsx";
import Login from "~/views/Login.jsx";
import NovaSenha from "~/views/EsqueciSenha.jsx";
//import Usuarios from "~/views/Usuarios/Usuarios.jsx";
//import ICMSs from "~/views/ICMSs/ICMSs.jsx";
import PesquisaUsuario from "./views/PesquisaUsuario/PesquisaUsuario.jsx";
import CadastroUsuario from "./views/CadastroUsuario/CadastroUsuario.jsx"

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    //layout: "/admin",
    layout: "",
  },
  {
    collapse: true,
    name: "Cadastro",
    icon: "tim-icons icon-pencil",
    state: "cadastroCollapse",
    views: [
      {
        collapse: true,
        name: "Pessoas",
        icon: " tim-icons icon-badge",
        state: "pessoasCollapse",
        className: "sideBarSubMenu",
        views: [
          {
            path: "/usuario",
            name: "Usuários Login",
            mini: "US",
            component: CadastroUsuario,
            layout: "",
          },
        ],
      },
      /* {
        collapse: true,
        name: "Fiscal",
        icon: " tim-icons icon-coins",
        state: "FiscalCollapse",
        className: "sideBarSubMenu",
        views: [
          {
            path: "/icmss",
            name: "ICMSs",
            mini: "IM",
            component: ICMSs,
            layout: "",
          },
        ],
      }, */
    ],
  },

  {
    collapse: true,
    name: "Pesquisas",
    icon: "tim-icons icon-zoom-split",
    state: "pesquisasCollapse",
    views: [
      {
        path: "/pesquisaUsuario",
        name: "Usuários",
        mini: "PU",
        component: PesquisaUsuario,
        //layout: "/admin",
        layout: "",
      },
    ],
  },
   {
    path: "/login",
    name: "Sair",
    component: Login,
    icon: "tim-icons icon-button-power",
    layout: "/auth",
  },
   {
    path: "/novaSenha",
    component: NovaSenha,
    layout: "/auth",
    redirect: true,
  },
];

export default routes;
