//State da tela de Pesquisa de Usuarios

var INITIAL_STATE = {

  pesquisaUsuario: {
      idEmpresa: 0,
      idUsuario: '',
      nomeUsuario: '',
      cpf: '',
  },

  USUARIO: {
    idEmpresa: 0,
    idUsuario: '',
    nomeUsuario: '',
    cpf: '',
  },

  invalido: {
      cpf: false,
      nome: false,
  },

  pesqempresa: [],
  EMPRESAs: [],
  USUARIOs: [],
  //inicioCadastro: false,
  //pesqregime: [],
  //cadregime: [],
  //ICMSCopia: {},

}

export default function PESQUISA_USUARIOS(state = INITIAL_STATE, action) {

  switch (action.idStore) {

      case 'PESQUISA_USUARIOS':
          switch (action.type) {
              case 'PESQUISA':
                  return { ...state, pesquisaUsuario: { ...state.pesquisaUsuario, [action.campo]: action.value } }
              case 'EMPRESAS':
                  return { ...state, EMPRESAs: action.value }  
              case 'EMPRESA_PESQ':
                  return { ...state, pesqempresa: action.value }
              case 'USUARIOS':
                  return { ...state, USUARIOs: action.value }
              case 'CLEAN_SEARCH':
                  return {
                      ...state,
                      pesquisaUsuario: {
                        idEmpresa: 0,
                        idUsuario: '',
                        nomeUsuario: '',
                        cpf: '',                                        },
                      USUARIOs: []
                  }
              case 'INVALIDO':
                  return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
              case 'USUARIO':
                  return { ...state, USUARIO: { ...state.USUARIO, [action.campo]: action.value } }
              case 'CLEAN_USUARIO':
                  return {
                      ...state,
                      USUARIO: {
                        idEmpresa: 0,
                        idUsuario: '',
                        nomeUsuario: '',
                        cpf: '',                    
                      }
                  }
              case 'CLEAN_INVALIDO':
                  return {
                      ...state,
                      invalido: {
                        cpf: false,
                        nome: false,                                    
                      }
                  }

              default:
                  return { ...state }
          }
      default:
          return { ...state }
  }
}




