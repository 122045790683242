//State da tela de Pesquisa e cadastro de usuários
var INITIAL_STATE = {
    pesquisa: {
        nome: '',
        email: '',
        cpf: '',
        ativos: 0,
    },
    cadastro: {
        codEmpresa: '',
        codUser: '',
        codFunc: '',
        codGrupo: '',
        nomeGrupo: '',
        nome: '',
        apelido: '',
        email: '',
        emailFunc: '',
        cpf: '',
        foto: '',
        grupo: '',
        telefone: '',
        dtNascimento: '',
        genero: 'O',
        rg: '',
        orgaoEmissor: '',
        dtEmissao: '',
        status: 0
    },
    invalido: {
        nome: false,
        email: false,
        emailFunc: false,
        cpf: false,
        dtNascimento: false,
        dtEmissao: false,
    },
    inicioCadastro: false,
    proximoCadastro: false,
    usuarios: [],
    cadastroCopia: {},
};

export default function usuarios(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'USUARIOS':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'USUARIOS':
                    return { ...state, usuarios: action.value }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: {
                            nome: '',
                            email: '',
                            cpf: '',
                            ativos: 0,
                        },
                        usuarios: []
                    }
                case 'CADASTRO':
                    return { ...state, cadastro: { ...state.cadastro, [action.campo]: action.value } }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'INICIO_CADASTRO':
                    return { ...state, inicioCadastro: action.value }
                case 'PROXIMO_CADASTRO':
                    return { ...state, proximoCadastro: action.value }
                case 'CADASTRO_COPIA':
                    return { ...state, cadastroCopia: state.cadastro }
                case 'CADASTRO_COPIA_STATUS':
                    return { ...state, cadastroCopia: { ...state.cadastro, status: action.value } }
                case 'CLEAN_CADASTRO':
                    return {
                        ...state,
                        cadastro: {
                            ...state.cadastro,
                            codUser: '',
                            codFunc: '',
                            codGrupo: '',
                            nomeGrupo: '',
                            nome: '',
                            apelido: '',
                            email: '',
                            emailFunc: '',
                            cpf: '',
                            foto: '',
                            grupo: '',
                            telefone: '',
                            dtNascimento: '',
                            genero: 'O',
                            rg: '',
                            orgaoEmissor: '',
                            dtEmissao: '',
                            status: 0
                        }
                    }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: {
                            nome: false,
                            email: false,
                            emailFunc: false,
                            cpf: false,
                            dtNascimento: false,
                            dtEmissao: false,
                        }
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}