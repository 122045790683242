import axios from "axios"
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import { ValidaCPF, ValidaData, ValidaEmail } from "~/Util/validacao";
import { campoValido } from "~/Util/campoValido";

const idStore = 'PESQUISA_USUARIOS';


export function Pesquisar(notificacao, dispatch, pesquisa, idEmpresa) {

    try {

      /*---------------------------------------------------------------------------
       * Valida Campos
       * Quando idEmpresa = 0 modificar para "T" (Todas)
       * Empresa Todas = Deve-se passar o CPF ou Nome 
      ---------------------------------------------------------------------------*/
/*       var idEmpresa = 'T';
      if (pesquisa.idEmpresa === 0){
        if ((pesquisa.cpf === "" || pesquisa.cpf === undefined) && (pesquisa.nomeUsuario === "" || pesquisa.nomeUsuario === undefined)){
          document.getElementById("cpf").focus();
          alertar(notificacao, "br", "Digite um CPF ou NOME para pesquisar.", "info", "icon-bulb-63", 2);
          return;    
        }
      }else {
        idEmpresa = pesquisa.idEmpresa;
      } */

      //var idEmpresa = '';
      //idEmpresa = pesquisa.idEmpresa;        

      var ulrPesquisa = '';
      

      if (pesquisa.cpf !== "") {
        ulrPesquisa = api.urlIntegradorApi + api.urlPesqUsuarioCpf + `${idEmpresa}` + `/${pesquisa.cpf}`;
      }else if (pesquisa.nomeUsuario !== "") {
        ulrPesquisa = api.urlIntegradorApi + api.urlPesqUsuarioNome + `${idEmpresa}` + `/${pesquisa.nomeUsuario}`;
      }
      else{
        ulrPesquisa = api.urlIntegradorApi + api.urlPesqUsuarios + `${idEmpresa}`;
      }
  
    dispatch({ idStore, type: 'USUARIOS', value: [] })  

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        //url: api.urlIntegradorApi + `usuariopesq/usuariosempresa/${pesquisa.idEmpresa}`,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
        if (response.data.data.length === 0) {
            alertar(notificacao, 'br', "Nenhum Usuário encontrado", 'info', 'icon-bulb-63', 2)
        }
        dispatch({ idStore, type: 'USUARIOS', value: response.data.data })
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }
}
