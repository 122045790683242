import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import global from "~/Util/global";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

import * as controllerDashboard from "~/controller/controllerDashboard";

import { useState, useEffect } from "react";
//import ReactTable from "~/components/ReactTable/ReactTable.js";
import ReactTable from "react-table";
//import ReactTable from "react-table-6";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Progress,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4,
} from "~/variables/charts.jsx";


var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
};


var departamentos = localStorage.getItem('DEPARTAMENTOS');
var perfisCadastrados = localStorage.getItem('PERFIS');
var usuariosAtivos = localStorage.getItem('USUARIOS_ATIVOS');
var errosDia = localStorage.getItem('ERROS');
var empresaNome = localStorage.getItem('EMPRESA_NOME');
var qtdUsuariosPerfilIntegrados = localStorage.getItem('QTD_USUARIO_PERFIL');
var qtdCursosIntegrados = localStorage.getItem('QTD_CURSOS');
var qtdDepartamentosIntegrados = localStorage.getItem('QTD_DEPARTAMENTOS_INTEGRADOS');
var ultimasIntegracoes = localStorage.getItem('ULTIMAS_INTEGRACOES');
var objUltimasIntegracoes = JSON.parse(ultimasIntegracoes);
var ultimosErros = localStorage.getItem('ULTIMOS_ERROS');
var objUltimosErros = JSON.parse(ultimosErros);
var logoEmpresa = '';
if (objUltimasIntegracoes != null){
  //logoEmpresa = require(objUltimasIntegracoes.logoEmpresa); 
  //logoEmpresa = require("../assets/img/Frimesa.jpg") 
}



class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    
    //const [data, setData] = ([]);

    departamentos = localStorage.getItem('DEPARTAMENTOS');
    perfisCadastrados = localStorage.getItem('PERFIS');
    usuariosAtivos = localStorage.getItem('USUARIOS_ATIVOS');
    errosDia = localStorage.getItem('ERROS');
    empresaNome = localStorage.getItem('EMPRESA_NOME');    
    qtdUsuariosPerfilIntegrados = localStorage.getItem('QTD_USUARIO_PERFIL');
    qtdDepartamentosIntegrados = localStorage.getItem('QTD_DEPARTAMENTOS_INTEGRADOS');
    qtdCursosIntegrados = localStorage.getItem('QTD_CURSOS');
    ultimasIntegracoes = localStorage.getItem('ULTIMAS_INTEGRACOES');
    objUltimasIntegracoes = JSON.parse(ultimasIntegracoes);
    if (objUltimasIntegracoes != null){ 
      if (objUltimasIntegracoes.idEmpresa === 1) {
        logoEmpresa = global.urlImagem + "Lar.jpg" //require("~/assets/img/Lar.jpg");
      }else if (objUltimasIntegracoes.idEmpresa === 2) {
        logoEmpresa = require("~/assets/img/SemImagem.jpg");
      }else if (objUltimasIntegracoes.idEmpresa === 3) {
        logoEmpresa = require("~/assets/img/SemImagem.jpg");
      }else if (objUltimasIntegracoes.idEmpresa === 4) {
        logoEmpresa = require("~/assets/img/Frimesa.jpg");
      }else if (objUltimasIntegracoes.idEmpresa === 5) {
        logoEmpresa = require("~/assets/img/Frisia.jpg");
      }else if (objUltimasIntegracoes.idEmpresa === 6) {
        logoEmpresa = require("~/assets/img/SemImagem.jpg");
      }else if (objUltimasIntegracoes.idEmpresa === 7) {
        logoEmpresa = require("~/assets/img/SemImagem.jpg");
      }else if (objUltimasIntegracoes.idEmpresa === 8) {
        logoEmpresa = require("~/assets/img/SemImagem.jpg");
      }else if (objUltimasIntegracoes.idEmpresa === 9) {
        logoEmpresa = require("~/assets/img/SemImagem.jpg");
      }
    }

    ultimosErros = localStorage.getItem('ULTIMOS_ERROS');
    objUltimosErros = JSON.parse(ultimosErros);    
        
    if (departamentos === "" || departamentos === null){
        departamentos = 0;
    }  

    if (perfisCadastrados === "" || perfisCadastrados === null){
      perfisCadastrados = 0;
    }  

    if (usuariosAtivos === "" || usuariosAtivos === null){
      usuariosAtivos = 0;
    }  

    if (errosDia === "" || errosDia === null){
      errosDia = 0;
    }  

    if (qtdUsuariosPerfilIntegrados === "" || qtdUsuariosPerfilIntegrados === null){
      qtdUsuariosPerfilIntegrados = 0;
    }  

    this.state = {
      bigChartData: "data1",
    };
    
  }



  setBgChartData = (name) => {
    this.setState({
      bigChartData: name,
    });
  };

/*     data = this.setState(
      dataTable.map((prop, key) => {
        return {
          id: key,
          name: prop[0],
          position: prop[1],
          office: prop[2],
          age: prop[3],
        };
      })
    ); */

  render() {

    return (
      <>
        <div className="content">
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="6">
                      <h5 className="card-category">{empresaNome}</h5>
                      <CardTitle tag="h2">Usuários Integrados</CardTitle>
                    </Col>
                    <Col sm="6">
                      <ButtonGroup
                        className="btn-group-toggle float-right"
                        data-toggle="buttons"
                      >
                        <Button
                          color="info"
                          id="0"
                          size="sm"
                          tag="label"
                          className={classNames("btn-simple", {
                            active: this.state.bigChartData === "data1",
                          })}
                          onClick={() => this.setBgChartData("data1")}
                        >
                          <input defaultChecked name="options" type="radio" />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Mensal
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-single-02" />
                          </span>
                        </Button>
                        <Button
                          color="info"
                          id="1"
                          size="sm"
                          tag="label"
                          className={classNames("btn-simple", {
                            active: this.state.bigChartData === "data2",
                          })}
                          onClick={() => this.setBgChartData("data2")}
                        >
                          <input name="options" type="radio" />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Semanal
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-gift-2" />
                          </span>
                        </Button>
                        <Button
                          color="info"
                          id="2"
                          size="sm"
                          tag="label"
                          className={classNames("btn-simple", {
                            active: this.state.bigChartData === "data3",
                          })}
                          onClick={() => this.setBgChartData("data3")}
                        >
                          <input name="options" type="radio" />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Diário
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-tap-02" />
                          </span>
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Line
                      data={chartExample1[this.state.bigChartData]}
                      options={chartExample1.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-warning">
                        <i className="tim-icons icon-molecule-40" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Departamentos</p>
                        <CardTitle tag="h3">{departamentos}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="tim-icons icon-refresh-01" /> Departamentos Cadastrados
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-primary">
                        <i className="tim-icons icon-shape-star" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Perfis</p>
                        <CardTitle tag="h3">{perfisCadastrados}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="tim-icons icon-sound-wave" /> Perfis Cadastrados
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-success">
                        <i className="tim-icons icon-single-02" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Usuários Ativos</p>
                        <CardTitle tag="h3">{usuariosAtivos}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="tim-icons icon-trophy" /> Usuários Ativos na Base
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-danger">
                        <i className="tim-icons icon-settings" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Erros</p>
                        <CardTitle tag="h3">{errosDia}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="tim-icons icon-watch-time" /> Nas últimas horas
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Perfis de Usuários Integrados</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-badge text-info" />{" "}
                    {qtdUsuariosPerfilIntegrados}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Line
                      data={chartExample2.data}
                      options={chartExample2.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Departamentos Integrados</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-delivery-fast text-primary" />{" "}
                    {qtdDepartamentosIntegrados}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Bar
                      data={chartExample3.data}
                      options={chartExample3.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Cursos Integrados</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-send text-success" /> {qtdCursosIntegrados}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Line
                      data={chartExample4.data}
                      options={chartExample4.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="5">
              <Card className="card-tasks">
{/*                 <CardHeader>
                  <h6 className="title d-inline">Tasks(5)</h6>
                  <p className="card-category d-inline">today</p>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      caret
                      className="btn-icon"
                      color="link"
                      data-toggle="dropdown"
                      type="button"
                    >
                      <i className="tim-icons icon-settings-gear-63" />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Action
                      </DropdownItem>
                      <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Another action
                      </DropdownItem>
                      <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Something else
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader> */}
                
{/*                 <CardBody>
                  <div className="table-full-width table-responsive">
                    <Table>
                      <tbody>
                        <tr>
                          <td>
                            <FormGroup check>
                              <Label check>
                                <Input defaultValue="" type="checkbox" />
                                <span className="form-check-sign">
                                  <span className="check" />
                                </span>
                              </Label>
                            </FormGroup>
                          </td>
                          <td>
                            <p className="title">Update the Documentation</p>
                            <p className="text-muted">
                              Dwuamish Head, Seattle, WA 8:47 AM
                            </p>
                          </td>
                          <td className="td-actions text-right">
                            <Button
                              color="link"
                              id="tooltip786630859"
                              title=""
                              type="button"
                            >
                              <i className="tim-icons icon-pencil" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip786630859"
                            >
                              Edit Task
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  defaultChecked
                                  defaultValue=""
                                  type="checkbox"
                                />
                                <span className="form-check-sign">
                                  <span className="check" />
                                </span>
                              </Label>
                            </FormGroup>
                          </td>
                          <td>
                            <p className="title">GDPR Compliance</p>
                            <p className="text-muted">
                              The GDPR is a regulation that requires businesses
                              to protect the personal data and privacy of Europe
                              citizens for transactions that occur within EU
                              member states.
                            </p>
                          </td>
                          <td className="td-actions text-right">
                            <Button
                              color="link"
                              id="tooltip155151810"
                              title=""
                              type="button"
                            >
                              <i className="tim-icons icon-pencil" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip155151810"
                            >
                              Edit Task
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <FormGroup check>
                              <Label check>
                                <Input defaultValue="" type="checkbox" />
                                <span className="form-check-sign">
                                  <span className="check" />
                                </span>
                              </Label>
                            </FormGroup>
                          </td>
                          <td>
                            <p className="title">Solve the issues</p>
                            <p className="text-muted">
                              Fifty percent of all respondents said they would
                              be more likely to shop at a company
                            </p>
                          </td>
                          <td className="td-actions text-right">
                            <Button
                              color="link"
                              id="tooltip199559448"
                              title=""
                              type="button"
                            >
                              <i className="tim-icons icon-pencil" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip199559448"
                            >
                              Edit Task
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <FormGroup check>
                              <Label check>
                                <Input defaultValue="" type="checkbox" />
                                <span className="form-check-sign">
                                  <span className="check" />
                                </span>
                              </Label>
                            </FormGroup>
                          </td>
                          <td>
                            <p className="title">Release v2.0.0</p>
                            <p className="text-muted">
                              Ra Ave SW, Seattle, WA 98116, SUA 11:19 AM
                            </p>
                          </td>
                          <td className="td-actions text-right">
                            <Button
                              color="link"
                              id="tooltip989676508"
                              title=""
                              type="button"
                            >
                              <i className="tim-icons icon-pencil" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip989676508"
                            >
                              Edit Task
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <FormGroup check>
                              <Label check>
                                <Input defaultValue="" type="checkbox" />
                                <span className="form-check-sign">
                                  <span className="check" />
                                </span>
                              </Label>
                            </FormGroup>
                          </td>
                          <td>
                            <p className="title">Export the processed files</p>
                            <p className="text-muted">
                              The report also shows that consumers will not
                              easily forgive a company once a breach exposing
                              their personal data occurs.
                            </p>
                          </td>
                          <td className="td-actions text-right">
                            <Button
                              color="link"
                              id="tooltip557118868"
                              title=""
                              type="button"
                            >
                              <i className="tim-icons icon-pencil" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip557118868"
                            >
                              Edit Task
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <FormGroup check>
                              <Label check>
                                <Input defaultValue="" type="checkbox" />
                                <span className="form-check-sign">
                                  <span className="check" />
                                </span>
                              </Label>
                            </FormGroup>
                          </td>
                          <td>
                            <p className="title">Arival at export process</p>
                            <p className="text-muted">
                              Capitol Hill, Seattle, WA 12:34 AM
                            </p>
                          </td>
                          <td className="td-actions text-right">
                            <Button
                              color="link"
                              id="tooltip143185858"
                              title=""
                              type="button"
                            >
                              <i className="tim-icons icon-pencil" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip143185858"
                            >
                              Edit Task
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
 */}

                <CardHeader>
                  <h5 className="title d-inline">Ocorrências de Integrações</h5>
                  <p className="card-category d-inline">Últimos dias</p>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">#</th>
                        <th>Data</th>
                        <th>Ocorrência</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">                          
                          {objUltimosErros === null ? "": objUltimosErros.statusErro[0] === "ERRO" ? 
                            <i className="tim-icons icon-settings text-danger" />: 
                            objUltimosErros.statusErro[0] === "ALERTA" ? 
                            <i className="tim-icons icon-alert-circle-exc text-warning" />: 
                            <i className="tim-icons icon-check-2 text-success" />}
                        </td>
                        <td>{objUltimosErros != null ? objUltimosErros.dataErro[0]: ""}</td>
                        <td id="descErro00">{objUltimosErros != null ? objUltimosErros.descricaoErro[0]: ""}</td>
                        <UncontrolledTooltip
                                delay={0}
                                target="descErro00"
                              >
                                {objUltimosErros != null ? objUltimosErros.toolTipErro[0]: ""}
                        </UncontrolledTooltip>
                      </tr>
                      <tr>
                        <td className="text-center">
                          {objUltimosErros === null ? "": objUltimosErros.statusErro[1] === "ERRO" ? 
                            <i className="tim-icons icon-settings text-danger" />: 
                            objUltimosErros.statusErro[1] === "ALERTA" ? 
                            <i className="tim-icons icon-alert-circle-exc text-warning" />: 
                            <i className="tim-icons icon-check-2 text-success" />}
                        </td>
                        <td>{objUltimosErros != null ? objUltimosErros.dataErro[1]: ""}</td>
                        <td id="descErro01">{objUltimosErros != null ? objUltimosErros.descricaoErro[1]: ""}</td>
                        <UncontrolledTooltip
                                delay={0}
                                target="descErro01"
                              >
                                {objUltimosErros != null ? objUltimosErros.toolTipErro[1]: ""}
                        </UncontrolledTooltip>                        
                      </tr>
                      <tr>
                        <td className="text-center">
                          {objUltimosErros === null ? "": objUltimosErros.statusErro[2] === "ERRO" ? 
                            <i className="tim-icons icon-settings text-danger" />: 
                            objUltimosErros.statusErro[2] === "ALERTA" ? 
                            <i className="tim-icons icon-alert-circle-exc text-warning" />: 
                            <i className="tim-icons icon-check-2 text-success" />}
                        </td>
                        <td>{objUltimosErros != null ? objUltimosErros.dataErro[2]: ""}</td>
                        <td id="descErro02">{objUltimosErros != null ? objUltimosErros.descricaoErro[2]: ""}</td>
                        <UncontrolledTooltip
                                delay={0}
                                target="descErro02"
                              >
                                {objUltimosErros != null ? objUltimosErros.toolTipErro[2]: ""}
                        </UncontrolledTooltip>
                      </tr>
                      <tr>
                        <td className="text-center">
                          {objUltimosErros === null ? "": objUltimosErros.statusErro[3] === "ERRO" ? 
                            <i className="tim-icons icon-settings text-danger" />: 
                            objUltimosErros.statusErro[3] === "ALERTA" ? 
                            <i className="tim-icons icon-alert-circle-exc text-warning" />: 
                            <i className="tim-icons icon-check-2 text-success" />}
                        </td>
                        <td>{objUltimosErros != null ? objUltimosErros.dataErro[3]: ""}</td>
                        <td id="descErro03">{objUltimosErros != null ? objUltimosErros.descricaoErro[3]: ""}</td>
                        <UncontrolledTooltip
                                delay={0}
                                target="descErro03"
                              >
                                {objUltimosErros != null ? objUltimosErros.toolTipErro[3]: ""}
                        </UncontrolledTooltip>
                      </tr>
                      <tr>
                        <td className="text-center">
                          {objUltimosErros === null ? "": objUltimosErros.statusErro[4] === "ERRO" ? 
                            <i className="tim-icons icon-settings text-danger" />: 
                            objUltimosErros.statusErro[4] === "ALERTA" ? 
                            <i className="tim-icons icon-alert-circle-exc text-warning" />: 
                            <i className="tim-icons icon-check-2 text-success" />}
                        </td>
                        <td>{objUltimosErros != null ? objUltimosErros.dataErro[4]: ""}</td>
                        <td id="descErro04">{objUltimosErros != null ? objUltimosErros.descricaoErro[4]: ""}</td>
                        <UncontrolledTooltip
                                delay={0}
                                target="descErro04"
                              >
                                {objUltimosErros != null ? objUltimosErros.toolTipErro[4]: ""}
                        </UncontrolledTooltip>
                      </tr>
                      <tr>
                        <td className="text-center">
                          {objUltimosErros === null ? "": objUltimosErros.statusErro[5] === "ERRO" ? 
                            <i className="tim-icons icon-settings text-danger" />: 
                            objUltimosErros.statusErro[5] === "ALERTA" ? 
                            <i className="tim-icons icon-alert-circle-exc text-warning" />: 
                            <i className="tim-icons icon-check-2 text-success" />}
                        </td>
                        <td>{objUltimosErros != null ? objUltimosErros.dataErro[5]: ""}</td>
                        <td id="descErro05">{objUltimosErros != null ? objUltimosErros.descricaoErro[5]: ""}</td>
                        <UncontrolledTooltip
                                delay={0}
                                target="descErro05"
                              >
                                {objUltimosErros != null ? objUltimosErros.toolTipErro[5]: ""}
                        </UncontrolledTooltip>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col lg="7">
              <Card>
                {/* ****************  ULTIMAS INTEGRACOES ***************** */}
                <CardHeader>
                  <CardTitle tag="h5">Últimas Integrações</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">#</th>
                        <th>Empresa</th>
                        <th>Data Inicial</th>
                        <th>Data Final</th>
                        {/* <th className="text-right">Salary</th> */}
                        <th className="text-center">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">
                          <div className="photo">
                            <img
                              alt="..."
                              //src={require("~/assets/img/Frimesa.jpg")}
                              src={logoEmpresa != '' ? logoEmpresa: require("~/assets/img/SemImagem.jpg")}
                            />
                          </div>
                        </td>
                        <td>{empresaNome}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataInicio[0]: "#"}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataFinal[0]: "#"}</td>
                        <td className="text-center">
{/*                           
                          if objUltimasIntegracoes != null then
                            if objUltimasIntegracoes.statusIntegracao[0] === "OK" then
                              icone sucesso
                            else
                              icone erro
                          else
                            "#"
                          fim
 */}                      {objUltimasIntegracoes != null ? 
                            objUltimasIntegracoes.statusIntegracao[0] === "OK" ? 
                            
                             <Button
                              className="btn-link btn-icon"
                              color="success"
                              id="tooltip618296632"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip618296632"
                              >
                                Sucesso
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-check-2" />
                            </Button>
                            : 
                            <Button
                              className="btn-link btn-icon"
                              color="danger"
                              id="tooltip707467505"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip707467505"
                              >
                                Possível Erro
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-simple-remove" />
                            </Button>
                          : "#"}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="photo">
                            <img
                              alt="..."
                              src={logoEmpresa != '' ? logoEmpresa: require("~/assets/img/SemImagem.jpg")}
                            />
                          </div>
                        </td>
                        <td>{empresaNome}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataInicio[1]: "#"}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataFinal[1]: "#"}</td>
                        <td className="text-center">
                          {objUltimasIntegracoes != null ? 
                            objUltimasIntegracoes.statusIntegracao[1] === "OK" ? 
                            
                             <Button
                              className="btn-link btn-icon"
                              color="success"
                              id="tooltip216846074"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip216846074"
                              >
                                Sucesso
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-check-2" />
                            </Button>
                            : 
                            <Button
                              className="btn-link btn-icon"
                              color="danger"
                              id="tooltip391990405"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip391990405"
                              >
                                Possível Erro
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-simple-remove" />
                            </Button>
                          : "#"}

                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="photo">
                            <img
                              alt="..."
                              src={logoEmpresa != '' ? logoEmpresa: require("~/assets/img/SemImagem.jpg")}
                            />
                          </div>
                        </td>
                        <td>{empresaNome}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataInicio[2]: "#"}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataFinal[2]: "#"}</td>
                        <td className="text-center">
                          {objUltimasIntegracoes != null ? 
                            objUltimasIntegracoes.statusIntegracao[2] === "OK" ? 
                            
                             <Button
                              className="btn-link btn-icon"
                              color="success"
                              id="tooltip191500186"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip191500186"
                              >
                                Sucesso
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-check-2" />
                            </Button>
                            : 
                            <Button
                              className="btn-link btn-icon"
                              color="danger"
                              id="tooltip320351170"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip320351170"
                              >
                                Possível Erro
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-simple-remove" />
                            </Button>
                          : "#"}

                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="photo">
                            <img
                              alt="..."
                              src={logoEmpresa != '' ? logoEmpresa: require("~/assets/img/SemImagem.jpg")}
                            />
                          </div>
                        </td>
                        <td>{empresaNome}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataInicio[3]: "#"}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataFinal[3]: "#"}</td>
                        <td className="text-center">
                          {objUltimasIntegracoes != null ? 
                            objUltimasIntegracoes.statusIntegracao[3] === "OK" ? 
                            
                             <Button
                              className="btn-link btn-icon"
                              color="success"
                              id="tooltip345411997"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip345411997"
                              >
                                Sucesso
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-check-2" />
                            </Button>
                            : 
                            <Button
                              className="btn-link btn-icon"
                              color="danger"
                              id="tooltip601343171"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip601343171"
                              >
                                Possível Erro
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-simple-remove" />
                            </Button>
                          : "#"}

                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="photo">
                            <img
                              alt="..."
                              src={logoEmpresa != '' ? logoEmpresa: require("~/assets/img/SemImagem.jpg")}
                            />
                          </div>
                        </td>
                        <td>{empresaNome}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataInicio[4]: "#"}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataFinal[4]: "#"}</td>
                        <td className="text-center">
                          {objUltimasIntegracoes != null ? 
                            objUltimasIntegracoes.statusIntegracao[4] === "OK" ? 
                            
                             <Button
                              className="btn-link btn-icon"
                              color="success"
                              id="tooltip774891382"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip774891382"
                              >
                                Sucesso
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-check-2" />
                            </Button>
                            : 
                            <Button
                              className="btn-link btn-icon"
                              color="danger"
                              id="tooltip949929353"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip949929353"
                              >
                                Possível Erro
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-simple-remove" />
                            </Button>
                          : "#"}

                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="photo">
                            <img
                              alt="..."
                              src={logoEmpresa != '' ? logoEmpresa: require("~/assets/img/SemImagem.jpg")}
                            />
                          </div>
                        </td>
                        <td>{empresaNome}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataInicio[5]: "#"}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataFinal[5]: "#"}</td>
                        <td className="text-center">
                          {objUltimasIntegracoes != null ? 
                            objUltimasIntegracoes.statusIntegracao[5] === "OK" ? 
                            
                             <Button
                              className="btn-link btn-icon"
                              color="success"
                              id="tooltip30547133"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip30547133"
                              >
                                Sucesso
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-check-2" />
                            </Button>
                            : 
                            <Button
                              className="btn-link btn-icon"
                              color="danger"
                              id="tooltip156899243"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip156899243"
                              >
                                Possível Erro
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-simple-remove" />
                            </Button>
                          : "#"}

                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            
{/*             <Col lg="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Global Sales by Top Locations</CardTitle>
                  <p className="card-category">
                    All products that were shipped
                  </p>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <Table responsive>
                        <tbody>
                          <tr>
                            <td>
                              <div className="flag">
                                <img
                                  alt="..."
                                  src={require("~/assets/img/US.png")}
                                />
                              </div>
                            </td>
                            <td>USA</td>
                            <td className="text-right">2.920</td>
                            <td className="text-right">53.23%</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="flag">
                                <img
                                  alt="..."
                                  src={require("~/assets/img/DE.png")}
                                />
                              </div>
                            </td>
                            <td>Germany</td>
                            <td className="text-right">1.300</td>
                            <td className="text-right">20.43%</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="flag">
                                <img
                                  alt="..."
                                  src={require("~/assets/img/AU.png")}
                                />
                              </div>
                            </td>
                            <td>Australia</td>
                            <td className="text-right">760</td>
                            <td className="text-right">10.35%</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="flag">
                                <img
                                  alt="..."
                                  src={require("~/assets/img/GB.png")}
                                />
                              </div>
                            </td>
                            <td>United Kingdom</td>
                            <td className="text-right">690</td>
                            <td className="text-right">7.87%</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="flag">
                                <img
                                  alt="..."
                                  src={require("~/assets/img/RO.png")}
                                />
                              </div>
                            </td>
                            <td>Romania</td>
                            <td className="text-right">600</td>
                            <td className="text-right">5.94%</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="flag">
                                <img
                                  alt="..."
                                  src={require("~/assets/img/BR.png")}
                                />
                              </div>
                            </td>
                            <td>Brasil</td>
                            <td className="text-right">550</td>
                            <td className="text-right">4.34%</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col className="ml-auto mr-auto" md="6">
                      <VectorMap
                        map={"world_mill"}
                        backgroundColor="transparent"
                        zoomOnScroll={false}
                        containerStyle={{
                          width: "100%",
                          height: "300px",
                        }}
                        regionStyle={{
                          initial: {
                            fill: "#e4e4e4",
                            "fill-opacity": 0.9,
                            stroke: "none",
                            "stroke-width": 0,
                            "stroke-opacity": 0,
                          },
                        }}
                        series={{
                          regions: [
                            {
                              values: mapData,
                              scale: ["#AAAAAA", "#444444"],
                              normalizeFunction: "polynomial",
                            },
                          ],
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col> */}

          </Row>
        </div>

{/*         <div className="content">
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">React Table</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={data}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: "Name",
                        accessor: "name",
                      },
                      {
                        Header: "Position",
                        accessor: "position",
                      },
                      {
                        Header: "Office",
                        accessor: "office",
                      },
                      {
                        Header: "Age",
                        accessor: "age",
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div> */}
      </>
    );
  }
}

export default Dashboard;
