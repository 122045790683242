//State da tela de Pesquisa e cadastro de grupos

var INITIAL_STATE = {

    pesquisa: {
        numeroICMS: '',
        descricao: '',
        codRegime: 0,
    },

    ICMS: {
        codICMS: '',
        numeroICMS: '',
        descricao: '',
        codRegime: 0,
        regime: '',
    },

    invalido: {
        numeroICMS: false,
        descricao: false,
        codRegime: false,
    },

    regimes: [],
    ICMSs: [],
    inicioCadastro: false,
    pesqregime: [],
    cadregime: [],
    ICMSCopia: {},

}

export default function ICMS(state = INITIAL_STATE, action) {

    switch (action.idStore) {

        case 'ICMS':
            switch (action.type) {
                case 'PESQUISA':
                    return { ...state, pesquisa: { ...state.pesquisa, [action.campo]: action.value } }
                case 'REGIMES':
                    return { ...state, regimes: action.value }
                case 'ICMSs':
                    return { ...state, ICMSs: action.value }
                case 'CLEAN_SEARCH':
                    return {
                        ...state,
                        pesquisa: {
                            numeroICMS: '',
                            descricao: '',
                            ativos: 0,
                            codRegime: "0",
                        },
                        ICMSs: []
                    }
                case 'INVALIDO':
                    return { ...state, invalido: { ...state.invalido, [action.campo]: action.value } }
                case 'ICMS':
                    return { ...state, ICMS: { ...state.ICMS, [action.campo]: action.value } }
                case 'INICIO_CADASTRO':
                    return { ...state, inicioCadastro: action.value }
                case 'ICMS_COPIA':
                    return { ...state, ICMSCopia: state.ICMS }
                case 'ICMS_COPIA_STATUS':
                    return { ...state, ICMSCopia: { ...state.ICMS, status: action.value } }
                case 'CLEAN_ICMS':
                    return {
                        ...state,
                        ICMS: {
                            codICMS: '',
                            numeroICMS: '',
                            descricao: '',
                            status: 0,
                            codRegime:"0",
                            regime: '',
                        }
                    }
                case 'REGIMES_PESQ':
                    return { ...state, pesqregime: action.value }
                case 'REGIMES_CAD':
                    return { ...state, cadregime: action.value }
                case 'CLEAN_INVALIDO':
                    return {
                        ...state,
                        invalido: {
                            numeroICMS: false,
                            descricao: false,
                            regime: false,
                        }
                    }

                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}




