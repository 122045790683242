//State de telas
var INITIAL_STATE = {
    telas: [],
    help: '',
};

export default function sistema(state = INITIAL_STATE, action) {
    switch (action.idStore) {
        case 'SISTEMA':
            switch (action.type) {
                case 'TELAS':
                    return { ...state, telas: action.value }
                case 'CLEAN':
                    return { ...state, telas: [] }
                case 'HELP':
                    return { ...state, help: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}