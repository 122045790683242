export function maskCPF(value) {
    if (value != null){
        value = value.toString()
        value = ("00000000000" + value).slice(-11);
        let newValue = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3\-\$4");
        return newValue    
    }
}

export function maskCEST(value) {
    let newValue = value.replace(/[\D]/g, '')
    let length = newValue.length
    if (length > 2 && length <= 5) {
        newValue = newValue.replace(/(\d{2})/, "$1.");
        return newValue
    }
    if (length > 5) {
        newValue = newValue.replace(/(\d{2})(\d{3})/, "$1.$2.");
        return newValue
    }
    return newValue
}

export function maskNCM(value) {
    let newValue = value.replace(/[\D]/g, '')
    let length = newValue.length
    if (length > 4 && length <= 6) {
        newValue = newValue.replace(/(\d{4})/, "$1.");
        return newValue
    }
    if (length > 6) {
        newValue = newValue.replace(/(\d{4})(\d{2})/, "$1.$2.");
        return newValue
    }
    return newValue
}

export function maskICMS(value) {
    value = value.toString()
    let newValue = value.replace(/[\D]/g, '')
    return newValue
}

export function maskCFOP(value) {
    let newValue = value.replace(/[\D]/g, '')
        let length = newValue.length
        if (length > 1) {
            newValue = newValue.replace(/(\d{1})/, "$1.");
            return newValue
        }
        return newValue
}

export function maskNumbers(value) {
    let newValue = value.replace(/[\D]/g, '')
    return newValue
}

export function maskIPI(value) {
    let newValue = value.replace(/[\D]/g, '')
    return newValue
}

export function maskEnquadramentoIPI(value) {
    let newValue = value.replace(/[\D]/g, '')
    return newValue
}
