import axios from "axios"
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import { ValidaCPF, ValidaData, ValidaEmail } from "~/Util/validacao";
import { campoValido } from "~/Util/campoValido";
import { func } from "prop-types";

const idStore = 'USUARIO_LOGIN';


export function Pesquisar(notificacao, dispatch, pesquisa, idEmpresa) {

    try {

      /*---------------------------------------------------------------------------
       * Valida Campos
      ---------------------------------------------------------------------------*/
/*       var idEmpresa = 'T';
      if (pesquisa.idEmpresa === 0){
        if ((pesquisa.nomeUsuario === "" || pesquisa.nomeUsuario === undefined) && (pesquisa.loginUsuario === "" || pesquisa.loginUsuario === undefined) && (pesquisa.emailUsuario === "" || pesquisa.emailUsuario === undefined)){
          document.getElementById("nomeUsuario").focus();
          alertar(notificacao, "br", "Digite um Nome ou Login ou Email para pesquisar.", "info", "icon-bulb-63", 2);
          return;    
        }
      }else {
        idEmpresa = pesquisa.idEmpresa;
      } */



      var ulrPesquisa = '';
      var nome = '-';
      var login = '-';
      var email = '-';

      if (pesquisa.nomeUsuario !== "") {
        nome = pesquisa.nomeUsuario;
      }else if (pesquisa.loginUsuario !== "") {
        login = pesquisa.loginUsuario;
      }else if (pesquisa.emailUsuario !== "") {
        email = pesquisa.emailUsuario;
      }

      ulrPesquisa = api.urlIntegradorApi + api.urlCadastroUsuarioPesquisa + `${idEmpresa}` + `/${nome}` + `/${login}` + `/${email}`;
  
      dispatch({ idStore, type: 'USUARIOS_LOGINs', value: [] })  

      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

      //const axios = require('axios');
/*       let data = JSON.stringify({
        "Login": login,
        "Email": email,
        "Nome": nome,
        "Empresa": idEmpresa
      });
 */
      let config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: ulrPesquisa,
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': bearerAuth
      },    
      };
      
      axios.request(config)
      .then((response) => {
      if (response.data.data.length === 0) {
          alertar(notificacao, 'br', "Nenhum Usuário encontrado", 'info', 'icon-bulb-63', 2)
      }
      dispatch({ idStore, type: 'USUARIOS_LOGINs', value: response.data.data })
      })




    } catch (e) {
        alertar(notificacao, 'br', "Não foi pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }
}
/*-------------------------------------------------------------------------------------
* Utilizado para INCLUIR ou ALTERAR 
* OPERACAO = 'I' = INCLUIR / 'A' = ALTERACAO
*-------------------------------------------------------------------------------------*/
export async function CreateUsuarioLogin(notificacao, dispatch, usuarioLogin, operacao, usuarioLoginCopia) {
  try {
      //Retorna se houve erro ou nao
      var ulrPesquisa = api.urlIntegradorApi + api.urlCadastroUsuario; 
      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

      if (operacao === 'I'){
        usuarioLogin.idUsuario = "0";
      }else{
        if (JSON.stringify(usuarioLogin) === JSON.stringify(usuarioLoginCopia)) {
          alertar(notificacao, 'br', "Nenhuma Alteraçõe foi Feita no Cadastro.", 'info', 'icon-bulb-63', 2);
          return false
        }        
      }

      var invalido = false;
      if (usuarioLogin.loginUsuario === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'login', value: true })
          //alertar(notificacao, 'br', "I", 'info', 'icon-bulb-63', 2);
          invalido = true
      }
      if (usuarioLogin.nomeUsuario === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'nome', value: true })
          invalido = true
      }
      if (usuarioLogin.emailUsuario === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'email', value: true })
          invalido = true
      }
      if (usuarioLogin.senha === '') {
        dispatch({ idStore, type: 'INVALIDO', campo: 'senha', value: true })
        invalido = true
      }

      if (usuarioLogin.senhaUsuario != usuarioLogin.senhaConfirmar) {
        dispatch({ idStore, type: 'INVALIDO', campo: 'senha', value: true })
        alertar(notificacao, 'br', "Senha Diferente da Confirmação da Senha.", 'info', 'icon-bulb-63', 2);
        invalido = true
      }

      if (invalido) {
          alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
          return true
      }
      return await axios({
        method: 'POST',
        url: ulrPesquisa,
        headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
        data: {
            IdUsuarioLogin: usuarioLogin.idUsuario,
            Login: usuarioLogin.loginUsuario,
            Senha: usuarioLogin.senhaUsuario,
            Email: usuarioLogin.emailUsuario,
            Nome: usuarioLogin.nomeUsuario,
            Foto: usuarioLogin.fotoUsuario,
            Empresa: usuarioLogin.empresa,
            Status: usuarioLogin.statusUsuario,
        }
      }).then(response => {
          alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
          var user = response.data.data;
          dispatch({ idStore, type: 'USUARIO_LOGIN', campo: 'idUsuario', value: user.IdUsuarioLogin })
          dispatch({ idStore, type: 'USUARIO_LOGIN_COPIA' })
          return false
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}


export async function GetUsuario(notificacao, dispatch, idUsuario, thenFunction){
  try {
    
        var ulrPesquisa = '';
        ulrPesquisa = api.urlIntegradorApi + api.urlCadastroUsuarioGetIdUsuario + `${idUsuario}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
        if (response.data.data.length === 0) {
            alertar(notificacao, 'br', "Nenhum Usuário encontrado", 'info', 'icon-bulb-63', 2)
        }
        let type = 'USUARIO_LOGIN';
        dispatch({ idStore, type, campo: 'idUsuario', value: response.data.data.idUsuarioLogin })
        dispatch({ idStore, type, campo: 'loginUsuario', value: response.data.data.login })
        dispatch({ idStore, type, campo: 'senhaUsuario', value: response.data.data.senha })
        dispatch({ idStore, type, campo: 'senhaConfirmar', value: response.data.data.senha })
        dispatch({ idStore, type, campo: 'emailUsuario', value: response.data.data.email })
        dispatch({ idStore, type, campo: 'nomeUsuario', value: response.data.data.nome })
        if (response.data.data.foto != null){
          //dispatch({ idStore, type, campo: 'fotoUsuario', value: 'data:image/jpg;base64,' + response.data.data.foto })
          dispatch({ idStore, type, campo: 'fotoUsuario', value: response.data.data.foto })
        }else{
          dispatch({ idStore, type, campo: 'fotoUsuario', value: "" })
        }
        dispatch({ idStore, type, campo: 'idEmpresa', value: response.data.data.empresa })
        dispatch({ idStore, type, campo: 'statusUsuario', value: response.data.data.status })

        dispatch({ idStore, type: 'USUARIO_LOGIN_COPIA' })
        thenFunction()

        //dispatch({ idStore, type: 'USUARIOS', value: response.data.data })
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}


/*-------------------------------------------------------------------------------------
* Utilizado para ATIVAR/INATIVAR 
* STATUS = 'A' = ATIVO ou 'I' = INATIVO
*-------------------------------------------------------------------------------------*/
export async function AtivarInativar(notificacao, dispatch, idUsuario, statusUsuario, thenFunction) {
  try {
        var ulr = '';
        ulr = api.urlIntegradorApi + api.urlCadastroUsuarioAtivarInativar + `${idUsuario}` + "/" + `${statusUsuario}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: ulr,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === "Sucesso"){
            alertar(notificacao, 'br', "Usuário Alterado com Sucesso.", 'success', 'icon-check-2', 2)
          }
          //var user = response.data.data;
          //dispatch({ idStore, type: 'USUARIO_LOGIN', campo: 'idUsuario', value: user.IdUsuarioLogin })
          //dispatch({ idStore, type: 'USUARIO_LOGIN_COPIA' })
          thenFunction()
          return false

        //dispatch({ idStore, type: 'USUARIOS', value: response.data.data })
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}





/* export async function GetUsuario(notificacao, dispatch, idEmpresa, idUsuario, thenFunction) {
  try {
      var basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
      await axios.get(api.urlCadastro + `api/v1/icms/ICMS?codEmpresa=${codEmpresa}&codIcms=${codICMS}`, {
          headers: { Authorization: basicAuth }
      }).then(function (response) {
          let type = 'ICMS';
          dispatch({ idStore, type, campo: 'codICMS', value: response.data.records.codIcms })
          dispatch({ idStore, type, campo: 'numeroICMS', value: response.data.records.icms })
          dispatch({ idStore, type, campo: 'descricao', value: response.data.records.descricao })
          dispatch({ idStore, type, campo: 'codRegime', value: response.data.records.codRegime })
          dispatch({ idStore, type, campo: 'status', value: response.data.records.status })
          dispatch({ idStore, type: 'ICMS_COPIA' })
          thenFunction()
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e);
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2, e)
  }
}
 */


/* export async function UpdateICMS(notificacao, dispatch, codEmpresa, ICMS, ICMSCopia) {
  try {
      //Retorna se houve erro ou nao
      if (JSON.stringify(ICMS) === JSON.stringify(ICMSCopia)) {
          alertar(notificacao, 'br', "Nada foi alterado", 'info', 'icon-bulb-63', 2);
          return false
      }

      const basicAuth = 'Basic ' + btoa(api.apiUser + ':' + localStorage.getItem('TOKEN_KEY'));
      var invalido = false;
      if (ICMS.numeroICMS === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'numeroICMS', value: true })
          alertar(notificacao, 'br', "Selecione o Regime", 'info', 'icon-bulb-63', 2);
          invalido = true
      }
      if (ICMS.descricao === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'descricao', value: true })
          invalido = true
      }
      if (ICMS.codRegime === 0) {
          dispatch({ idStore, type: 'INVALIDO', campo: 'codRegime', value: true })
          invalido = true
      }

      if (invalido) {
          alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
          return true
      }

      return await axios({
          method: 'put',
          url: api.urlCadastro + `api/v1/icms/Update?codEmpresa=${codEmpresa}&codIcms=${ICMS.codICMS}`,
          headers: { Authorization: basicAuth },
          data: {
              ICMS: ICMS.numeroICMS,
              Descricao: ICMS.descricao,
              CodRegime: ICMS.codRegime,
          }
      }).then(response => {
          alertar(notificacao, 'br', response.data.message, 'success', 'icon-check-2', 2)
          dispatch({ idStore, type: 'ICMS', campo: 'codICMS', value: response.data.records })
          dispatch({ idStore, type: 'ICMS_COPIA' })
          return false
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}
 */


/* axios({
  method: 'POST',
  url: ulrPesquisa,
  headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
  data: {
    empresa: idEmpresa,
  }
  }).then(response => {
    if (response.data.data.length === 0) {
      alertar(notificacao, 'br', "Nenhum Usuário encontrado", 'info', 'icon-bulb-63', 2)
  }
  dispatch({ idStore, type: 'USUARIOS_LOGINs', value: response.data.data })
  }) */
