import "./styles.css";

import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import ReactSelect from "react-select";

export function InputCpfCnpj(props) {
  const [valueInput, setValue] = useState("");
  const tipo = props.tipo === "cnpj" ? props.tipo : "cpf";

  useEffect(() => {
    setValue(props.value);
    setMask(props.value);
  }, [props.value]);

  function setMask(value) {
    let stringFormated = "";

    if (value.length < valueInput.length) {
      setValue(value);
      return;
    }

    value = value.replace(/\D/g, "");
    if (tipo === "cpf") {
      if (value.length > 3 && value.length <= 6) {
        stringFormated = value.replace(/(\d{3})(\d{1})/, "$1.$2");
        setValue(stringFormated);
        return;
      }

      if (value.length === 7) {
        stringFormated = value.replace(/(\d{3})(\d{3})/, "$1.$2.");
        setValue(stringFormated);
        return;
      }

      if (value.length <= 9) {
        stringFormated = value.replace(/(\d{3})(\d{3})(\d{1})/, "$1.$2.$3");
        setValue(stringFormated);
        return;
      }

      if (value.length > 9) {
        stringFormated = value.replace(
          /(\d{3})(\d{3})(\d{3})(\d{1})/,
          "$1.$2.$3-$4"
        );
        setValue(stringFormated);
        return;
      }
    } else if (tipo === "cnpj") {
      if (value.length > 2 && value.length <= 5) {
        stringFormated = value.replace(/(\d{2})(\d{1})/, "$1.$2");
        setValue(stringFormated);
        return;
      }

      if (value.length > 5 && value.length <= 8) {
        stringFormated = value.replace(/(\d{2})(\d{3})(\d{1})/, "$1.$2.$3");
        setValue(stringFormated);
        return;
      }

      if (value.length > 8 && value.length <= 12) {
        stringFormated = value.replace(
          /(\d{2})(\d{3})(\d{3})(\d{1})/,
          "$1.$2.$3/$4"
        );
        setValue(stringFormated);
        return;
      }

      if (value.length === 13) {
        stringFormated = value.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{1})/,
          "$1.$2.$3/$4-$5"
        );
        setValue(stringFormated);
        return;
      }

      if (value.length === 14) {
        stringFormated = value.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        );
        setValue(stringFormated);
        return;
      }
    }

    setValue(value);
  }

  return (
    <Input
      {...props}
      type="text"
      onChange={(e) => {
        props.onChange(e);
      }}
      value={valueInput}
      maxLength={props.tipo === "cnpj" ? 18 : 14}
    />
  );
}

export function InputTelefone(props) {
  const [valueInput, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
    setMask(props.value);
  }, [props.value]);

  function setMask(value) {
    let stringFormated = "";

    if (value.length < valueInput.length) {
      if (value.length === 14) {
        stringFormated = value.replace(/[()-\s]/g, "");
        stringFormated = stringFormated.replace(
          /(\d{2})(\d{4})(\d{4})/,
          "($1) $2-$3"
        );
        setValue(stringFormated);
        return;
      }

      setValue(value);
      return;
    }

    value = value.replace(/\D/g, "");
    if (value.length === 1) {
      stringFormated = value.replace(/(\d{1})/, "($1");
      setValue(stringFormated);
      return;
    }

    if (value.length === 2) {
      stringFormated = value.replace(/(\d{2})/, "($1) ");
      setValue(stringFormated);
      return;
    }

    if (value.length > 2 && value.length <= 6) {
      stringFormated = value.replace(/(\d{2})(\d{1})/, "($1) $2");
      setValue(stringFormated);
      return;
    }

    if (value.length > 6 && value.length <= 9) {
      stringFormated = value.replace(/(\d{2})(\d{4})(\d{1})/, "($1) $2-$3");
      setValue(stringFormated);
      return;
    }

    if (value.length === 10) {
      stringFormated = value.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
      setValue(stringFormated);
      return;
    }

    if (value.length === 11) {
      stringFormated = value.replace(/[()-\s]/g, "");
      stringFormated = stringFormated.replace(
        /(\d{2})(\d{5})(\d{4})/,
        "($1) $2-$3"
      );
      setValue(stringFormated);
      return;
    }

    setValue(value);
  }

  return (
    <Input
      {...props}
      type="text"
      onChange={(e) => {
        props.onChange(e);
      }}
      value={valueInput}
      maxLength={15}
    />
  );
}

export function Select(props = { options: [] }) {
  //props
  //value={value:'valor atual',label:'texto atual'} opcionais
  //select={value:'nome do campo que será o valor',label:'nome do campo que será o texto'}opcional
  //first=texto do primeiro campo com valor 0 opcional
  const [selected, setselected] = useState({ value: "0", label: props.first });
  const [options, setoptions] = useState([]);

  useEffect(() => {
    let obj = [];
    if (props.first) {
      obj.push({ value: "0", label: props.first });
    }
    props.options.forEach((option) => {
      if (props.select) {
        obj.push({
          value: option[props.select.value],
          label: option[props.select.label],
        });
      } else {
        obj.push({ value: option.value, label: option.label });
      }
    });
    setoptions(obj);
    setselected(props.options.find((option) => option.value === "0"));
  }, [props.options]);

  useEffect(() => {
    options.length > 0 &&
      setselected(
        options.find(
          (option) =>
            option.value === props.value.value ||
            option.label === props.value.label
        )
      );
  }, [props.value]);

  return (
    <ReactSelect
      {...props}
      className="react-select info"
      classNamePrefix="react-select"
      value={selected}
      options={options}
      onChange={(value) => props.onChange(value)}
      noOptionsMessage={() => "Não encontrado"}
    />
  );
}

export function Switch(props) {
  const ComponentTop = () => props.componenttop || <></>;
  const ComponentLeft = () => props.componentleft || <></>;
  const ComponentRight = () => props.componentright || <></>;
  return (
    <div className="switch-body">
      <div className="switch-container">
        {props.componenttop && <ComponentTop className="switch-text-top" />}

        <div className="switch-row">
          {props.componentleft && (
            <div className="switch-text-left">
              <ComponentLeft />
            </div>
          )}

          <label className="switch">
            <input {...props} type="checkbox" />
            <span className="slider round"></span>
          </label>

          {props.componentright && (
            <div className="switch-text-right">
              <ComponentRight />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function InputNumber(props) {
  const [valueInput, setvalue] = useState("0,00");
  const [digitos, setdigitos] = useState("10");
  const [decimais, setdecimais] = useState("2");

  useEffect(() => {
    if (props.format) {
      let format = props.format.split(",");
      setdigitos(parseInt(format[0]));
      setdecimais(parseInt(format[1]));
    }
  }, [props.format]);

  useEffect(() => {
    setMask(String(props.value));
  }, [props.value]);

  function setMask(value) {
    if (value < valueInput) {
      setvalue(value);
      return;
    }

    value = value.replace(".", ",").replace(/[^0-9,]/g, "");

    if (value.substring(0, 1) === ",") {
      value = value.replace(value.substring(0, 1), "0,");
    }

    let virgula = false;

    for (var i = 0; i < value.length; i++) {
      if (value.substring(i, i + 1) === ",") {
        if (virgula) {
          value = value.substring(0, i) + value.substring(i + 1, value.length);
        }
        virgula = true;
      }
    }

    if (!virgula) {
      if (value.length === digitos - decimais + 1) {
        let re = new RegExp(`(\\d{${digitos - decimais}})(\\d{1})`);
        value = value.replace(re, "$1,$2");
        setvalue(value);
        return;
      }
    } else {
      let valueDecimais = value.split(",")[1];

      if (valueDecimais.length > decimais) {
        return;
      }
    }
    change(value);
    setvalue(value);
  }

  function change(formated) {
    props.onChange({ target: { value: formated } });
  }

  return (
    <Input
      {...props}
      type="text"
      value={valueInput}
      maxLength={`${digitos + 1}`}
      onChange={(e) => {
        props.onChange(e);
      }}
    />
  );
}
